<app-loading
  *ngIf="csvHandlerState().loading"
  [value]="csvHandlerState().progressNumber"
  [title]="'Downloading ' + '('+ csvHandlerState().progressNumber + '%' + ')'"
/>
<p-card [header]="title">
  <p-toolbar styleClass="mb-4 space-y-2">
    <div class="p-toolbar-group-start">
      <app-item-filters></app-item-filters>
    </div>
    <div class="p-toolbar-group-end gap-4">
      <button
        (click)="downloadCSV()"
        class="p-button p-ripple primary-500"
        icon="pi pi-download"
        label="Download CSV"
        pButton
        pRipple
      ></button>
      <p-fileUpload
        (onUpload)="onUpload($event)"
        [auto]="true"
        [url]="importAssetsURL"
        accept=".csv"
        chooseLabel="Import Assets"
        mode="basic"
        name="file"
      >
      </p-fileUpload>
    </div>
  </p-toolbar>

  <app-item-datatable
    (onBulkDeleteItem)="bulkDeleteItem($event)"
    (onDeleteItem)="deleteItem($event)"
    (onEditItem)="editItem($event)"
    (onNewItem)="newItem()"
    (onViewItem)="viewItem($event)"
  >
  </app-item-datatable>
</p-card>
