import { Component, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { hasErrorAndTouched } from '@shared/form-controls.util';
import { LocationsService, LocationsServiceState } from '@modules/locations/services';
import { Location } from '@core/models';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { TimezoneService } from '@core/services';
import { VendorsService, VendorsServiceState } from '@modules/vendors/services';
import { SharedModule } from '@shared/shared.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const dayjs = require('dayjs');
const dayjsUTC = require('dayjs/plugin/utc');
const dayjsTZ = require('dayjs/plugin/timezone');
dayjs.extend(dayjsUTC); // use plugin
dayjs.extend(dayjsTZ); // use plugin

@UntilDestroy()
@Component({
  selector: 'app-location-form',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
})
export class LocationFormComponent implements OnInit {
  readonly locationsServiceState: Signal<LocationsServiceState> = this.locationService.state.asReadonly();
  readonly vendorServiceState: Signal<VendorsServiceState> = this.vendorService.state.asReadonly();
  readonly isEditMode = this.locationsServiceState().formEditMode;
  readonly selectedLocation: Location | undefined = this.locationsServiceState().selectedLocation;
  readonly hasErrorAndTouched = hasErrorAndTouched;
  timezones = this.timezoneService.list();
  locationForm: UntypedFormGroup = this.fb.group({
    name: ['', Validators.required],
    pic: [''],
    email: ['', [Validators.email]],
    city: [''],
    timezone: ['', Validators.required],
    postcode: [''],
    phone: [''],
  });

  constructor(
    private fb: FormBuilder,
    private locationService: LocationsService,
    public dialogService: DialogService,
    private vendorService: VendorsService,
    private timezoneService: TimezoneService,
  ) {}

  get disableSubmitForm(): boolean {
    return this.locationForm.invalid;
  }

  ngOnInit() {
    let formData!: Location;

    if (this.isEditMode) {
      formData = this.selectedLocation as Location;
    } else {
      const vendorId: string = this.vendorServiceState().vendors
        ? (this.vendorServiceState().vendors[0].id as string)
        : '';

      formData = {
        name: '',
        vendorId: vendorId,
        pic: '',
        email: '',
        city: '',
        timezone: this.timezones[0].value as string,
        postcode: '',
        phone: '',
      };
    }
    this.locationForm.patchValue(formData);
  }

  onSubmit() {
    const payload: Location = { ...this.locationForm.value };
    this.dialogService.dialogComponentRefMap.forEach((dialog) => {
      dialog.destroy();
    });

    if (this.isEditMode) {
      this.locationService
        .edit(this.selectedLocation?.id as string, payload)
        .pipe(untilDestroyed(this))
        .subscribe();
    } else {
      this.locationService.new(payload).subscribe();
    }
  }
}
