import { Component, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScanHistoriesService, ScanHistoryServiceState } from '@modules/scan-histories/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TablePageEvent } from 'primeng/table';
import { SharedModule } from '@shared/shared.module';
import { ToastService } from '@core/services';

@UntilDestroy()
@Component({
  selector: 'app-scan-history-datatable',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './scan-history-datatable.component.html',
  styleUrls: ['./scan-history-datatable.component.scss'],
})
export class ScanHistoryDatatableComponent implements OnInit {
  // The component subscribe and fetch data from service state
  readonly scanHistoryServiceState: Signal<ScanHistoryServiceState> = this.scanHistoryService.state.asReadonly();

  skeletonHeaders = new Array(9).fill('_');
  skeletonScanHistories = new Array(this.scanHistoryServiceState().paginatorState.rows).fill(1);

  constructor(
    public scanHistoryService: ScanHistoriesService,
    protected toastService: ToastService,
  ) {}

  ngOnInit() {
    this.scanHistoryService.resetPaginatorState();
    this.scanHistoryService.getAll().pipe(untilDestroyed(this)).subscribe();
  }

  onSort(event: any) {
    this.scanHistoryService.apiParams.sort = {
      field: event.field,
      order: event.order === 1 ? 'asc' : 'desc',
    };
    this.scanHistoryService.getAll().pipe(untilDestroyed(this)).subscribe();
  }

  onPageChange(event: TablePageEvent | any) {
    const isPerPageChanged = event.rows != this.scanHistoryServiceState().paginatorState.rows;

    this.scanHistoryService.set('paginatorState', {
      first: isPerPageChanged ? 0 : event.first,
      rows: event.rows,
    });

    this.scanHistoryService.apiParams.page = {
      number: isPerPageChanged ? 1 : event.page + 1,
      size: event.rows,
    };

    this.scanHistoryService.getAll().pipe(untilDestroyed(this)).subscribe();
  }
}
