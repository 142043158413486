import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private defaultConfig: Message = {
    key: 'global-toast',
    life: 2000,
  };

  constructor(private messageService: MessageService) {}

  open(message: Message) {
    this.messageService.add({ ...this.defaultConfig, ...message });
  }

  clear(key = 'global-toast') {
    this.messageService.clear(key);
  }

  onFormInvalid(message: string = 'Form is invalid!'): void {
    this.open({ severity: 'error', summary: 'Error', detail: message });
  }

  onHttpSuccess(message: string = 'Success'): void {
    this.open({ severity: 'success', summary: message, detail: '' });
  }

  onHttpError(message: string = 'Oops... Something went wrong'): void {
    this.open({ severity: 'error', summary: 'Error', detail: message });
  }

  onGetSuccess(message: string = 'Data fetched successfully!'): void {
    this.open({ severity: 'success', summary: 'Success', detail: message });
  }

  onGetError(message: string = 'Failed to fetch data!'): void {
    this.open({ severity: 'error', summary: 'Error', detail: message });
  }

  onCreateSuccess(message: string = 'Created!'): void {
    this.open({ severity: 'success', summary: message, detail: '' });
  }

  onCreateError(message: string = 'Create Failed!'): void {
    this.open({ severity: 'error', summary: message, detail: '' });
  }

  onUpdateSuccess(message: string = 'Updated!'): void {
    this.open({ severity: 'success', summary: message, detail: '' });
  }

  onUpdateError(message: string = 'Update Failed!'): void {
    this.open({ severity: 'error', summary: message, detail: '' });
  }

  onCopySuccess() {
    this.open({ severity: 'info', summary: 'Copied', detail: '' });
  }

  onDeleteSuccess(message: string = 'Deleted!'): void {
    this.open({ severity: 'success', summary: message, detail: '' });
  }

  onDeleteError(message: string = 'Delete Failed!'): void {
    this.open({ severity: 'error', summary: message, detail: '' });
  }
}
