import { Component, EventEmitter, OnInit, Output, Signal } from '@angular/core';
import { Item } from '@core/models';
import { ItemsService, ItemsServiceState } from '@modules/items/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TablePageEvent } from 'primeng/table';
import { SharedModule } from '@shared/shared.module';
import { CommonModule } from '@angular/common';
import { ToastService } from '@core/services';

@UntilDestroy()
@Component({
  selector: 'app-item-datatable',
  templateUrl: './item-datatable.component.html',
  styleUrls: ['./item-datatable.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class ItemDatatableComponent implements OnInit {
  @Output() onNewItem: EventEmitter<any> = new EventEmitter<Item>();
  @Output() onViewItem: EventEmitter<any> = new EventEmitter<Item>();
  @Output() onEditItem: EventEmitter<any> = new EventEmitter<Item>();
  @Output() onDeleteItem: EventEmitter<any> = new EventEmitter<Item>();
  @Output() onBulkDeleteItem: EventEmitter<any> = new EventEmitter<string[]>();

  // The component subscribe and fetch data from service state
  readonly itemsServiceState: Signal<ItemsServiceState> = this.itemsService.state.asReadonly();

  skeletonHeaders = new Array(11).fill('_');
  skeletonItems = new Array(this.itemsServiceState().paginatorState.rows).fill(1);
  relationSort: string[] = ['vendor', 'location', 'assetType'];
  _selectedItems!: Item[] | null;

  constructor(
    public itemsService: ItemsService,
    protected toastService: ToastService,
  ) {}

  ngOnInit() {
    this.itemsService.resetPaginatorState();
    this.itemsService.fetchItems().pipe(untilDestroyed(this)).subscribe();
  }

  newItem() {
    this.onNewItem.emit();
  }

  viewItem(item: Item) {
    this.onViewItem.emit(item);
  }

  editItem(item: Item) {
    this.onEditItem.emit(item);
  }

  deleteItem(item: Item) {
    this.onDeleteItem.emit(item);
  }

  bulkDelete() {
    this.onBulkDeleteItem.emit(this._selectedItems?.map((item) => item.id) as string[]);
  }

  onSort(event: any) {
    if (event) {
      if (this.relationSort.includes(event.field)) {
        this.itemsService.apiParams.sort = {
          field: event.field,
          order: JSON.stringify({ name: event.order === 1 ? 'asc' : 'desc' }),
        };
      } else {
        this.itemsService.apiParams.sort = {
          field: event.field,
          order: event.order === 1 ? 'asc' : 'desc',
        };
      }
      this.itemsService.fetchItems().pipe(untilDestroyed(this)).subscribe();
    }
  }

  onPageChange(event: TablePageEvent | any) {
    const isPerPageChanged = event.rows != this.itemsServiceState().paginatorState.rows;
    this._selectedItems = null;
    this.itemsService.set('paginatorState', {
      first: isPerPageChanged ? 0 : event.first,
      rows: event.rows,
    });

    this.itemsService.apiParams.page = {
      number: isPerPageChanged ? 1 : event.page + 1,
      size: event.rows,
    };

    this.itemsService.fetchItems().pipe(untilDestroyed(this)).subscribe();
  }
}
