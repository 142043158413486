import { Component, OnInit, Signal } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { hasErrorAndTouched } from '@shared/form-controls.util';
import { VendorsService, VendorsServiceState } from '@modules/vendors/services';
import { Vendor } from '@core/models';
import { SharedModule } from '@shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-vendor-form',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ButtonModule,
    InputSwitchModule,
    InputTextModule,
    NgIf,
    ReactiveFormsModule,
    RippleModule,
    InputTextareaModule,
    ReactiveFormsModule,
  ],
  templateUrl: './vendor-form.component.html',
  styleUrls: ['./vendor-form.component.scss'],
})
export class VendorFormComponent implements OnInit {
  readonly vendorsServiceState: Signal<VendorsServiceState> = this.vendorService.state.asReadonly();
  readonly isEditMode = this.vendorsServiceState().formEditMode;
  readonly selectedVendor: Vendor | undefined = this.vendorsServiceState().selectedVendor;
  readonly hasErrorAndTouched = hasErrorAndTouched;

  vendorForm: UntypedFormGroup = this.fb.group({
    name: ['', Validators.required],
    address: [''],
    pic: [''],
    phone: [''],
    status: [true],
  });

  constructor(
    private fb: FormBuilder,
    private vendorService: VendorsService,
    public dialogService: DialogService,
  ) {}

  get disableSubmitForm(): boolean {
    return this.vendorForm.invalid;
  }

  ngOnInit() {
    if (this.isEditMode) {
      this.vendorForm.patchValue(this.selectedVendor as Vendor);
    }
  }

  onSubmit() {
    const payload: Vendor = { ...this.vendorForm.value };
    this.dialogService.dialogComponentRefMap.forEach((dialog) => {
      dialog.destroy();
    });

    if (this.isEditMode) {
      this.vendorService.edit(this.selectedVendor?.id as string, payload).subscribe();
    } else {
      this.vendorService.new(payload).subscribe();
    }
  }
}
