import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Interceptor for adding timezone parameter to HTTP requests.
 */
@Injectable({
  providedIn: 'root',
})
export class TimezoneInterceptor implements HttpInterceptor {
  /**
   * Intercepts HTTP requests and adds the timezone parameter to the request URL.
   * The timezone is obtained from the user's browser using the Intl.DateTimeFormat API.
   * @param req - The original HTTP request.
   * @param next - The next HTTP handler in the chain.
   * @returns An Observable of the HTTP event stream.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = req.clone({
      setParams: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
    return next.handle(request);
  }
}
