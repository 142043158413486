import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { AuthService } from '@core/services';
import { RouterOutlet } from '@angular/router';
import { fader } from './route.animation';

/**
 * The root component of the application.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fader],
})
export class AppComponent implements AfterViewInit, AfterContentChecked {
  /** Indicates whether the component is expanded or not. */
  expanded!: boolean;
  /** Indicates whether the loader is active or not. */
  loader: boolean = true;

  constructor(
    public authService: AuthService,
    private changeRef: ChangeDetectorRef,
  ) {}

  /**
   * Lifecycle hook that is called after the component's view has been fully initialized.
   */
  ngAfterViewInit() {
    // sessionStorage.setItem('session', JSON.stringify({name: 'Dang', tokens: {accessToken: 'DUMMY_ACCESS_TOKEN'}}));
    setTimeout(() => {
      this.loader = false;
    }, 300);
  }

  /**
   * Lifecycle hook that is called after every check of the component's content.
   */
  ngAfterContentChecked() {
    this.changeRef.detectChanges();
  }

  /**
   * Returns the animation data for the current route.
   * @param outlet - The router outlet.
   * @returns The animation data for the current route.
   */
  prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData['animation'] || 'asset-management';
  }

  /**
   * Resets the sidebar to its initial state.
   */
  resetSidebar() {
    this.expanded = true;
  }

  /**
   * Handles the event when the sidebar is expanded or collapsed.
   */
  onExpanded() {
    this.expanded = !this.expanded;
  }
}
