import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TagInfoComponent } from '@modules/items/components/item-detail/tag-info/tag-info.component';
import { VendorInfoComponent } from '@modules/items/components/item-detail/vendor-info/vendor-info.component';
import { ScanHistoryComponent } from '@modules/items/components/item-detail/scan-history/scan-history.component';

@Component({
  selector: 'app-item-detail',
  standalone: true,
  imports: [CommonModule, SharedModule, TagInfoComponent, VendorInfoComponent, ScanHistoryComponent],
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss'],
})
export class ItemDetailComponent {}
