<div *ngIf="loader" class="flex w-full h-full items-center justify-center">
  <app-entry></app-entry>
</div>
<div *ngIf="!loader && !authService.authenticated">
  <app-login></app-login>
</div>

<div *ngIf="!loader && authService.authenticated" class="flex flex-col h-screen bg-neutral-200 overscroll-none">
  <application-header
    class="fixed top-0 left-0 w-full p-auto flex items-center bg-white h-20 z-40"
  ></application-header>
  <div class="flex flex-grow h-max w-full justify-end m-auto p-auto relative">
    <app-sidebar
      (openSidebar)="onExpanded()"
      (resetSidebarWidth)="resetSidebar()"
      [ngClass]="expanded ? 'w-[350px]' : 'w-[48px]'"
      class="fixed bottom-0 left-0 h-full pt-20"
    ></app-sidebar>
    <div
      [@routeAnimations]="prepareRoute(outlet)"
      [ngClass]="expanded ? 'expand-width' : 'collapse-width'"
      class="relative p-5 bg-neutral-200 z-20 mt-20 duration-300"
    >
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </div>
</div>

<p-dynamicDialog></p-dynamicDialog>
<p-scrollTop></p-scrollTop>
<p-toast key="global-toast"></p-toast>
<p-confirmDialog></p-confirmDialog>
