import { Injectable } from '@angular/core';
import { AbstractApiService } from '@core/services';
import { Location } from '@core/models';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocationsApiService extends AbstractApiService<Location> {
  constructor() {
    super(`${environment.backend?.url}/locations`);
  }
}
