<form (ngSubmit)="onSubmit()" [formGroup]="vendorForm" autocomplete="off" class="w-full mt-5">
  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="name" id="name" pInputText/>
            <label htmlFor="name">Vendor name*</label>
        </span>
    <p-message *ngIf="hasErrorAndTouched(vendorForm.get('name'), 'required')"
               severity="error" text="*Vendor name is required."></p-message>
  </div>
  <div class="w-full mb-8">
        <span class="p-float-label">
          <textarea [autoResize]="true" cols="30" formControlName="address" id="address" pInputTextarea
                    rows="2"></textarea>
          <label htmlFor="address">Address</label>
        </span>
  </div>

  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="pic" id="pic" pInputText
            />
            <label htmlFor="pic">Contact Person</label>
        </span>
  </div>

  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="phone" id="phone" pInputText/>
            <label htmlFor="phone">Phone</label>
        </span>
  </div>


  <div class="flex flex-row space-x-4">
    <button [disabled]="disableSubmitForm"
            class="p-button p-ripple p-component mt-3"
            label="Save"
            pButton pRipple type="submit"></button>
  </div>
</form>
