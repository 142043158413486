import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
  intevalId?: NodeJS.Timeout;
  @Input('value') value: number = 0;
  @Input('title') title: string = 'Loading';
  @Input('completeTitle') completeTitle: string = 'Completed';
  titleSuffix = '';

  ngOnInit() {
    this.titleSuffixAnimation();
  }

  private titleSuffixAnimation() {
    this.intevalId = setInterval(() => {
      this.titleSuffix = this.titleSuffix.length >= 5 ? '' : this.titleSuffix + '.';
    }, 500);
  }

  ngOnDestroy() {
    clearInterval(this.intevalId);
  }
}
