import { Component, OnInit, Signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssetTypeService, ItemsService, ItemsServiceState } from './services';
import { Item } from '@core/models';
import { VendorsService } from '@modules/vendors/services';
import { SharedModule } from '@shared/shared.module';
import { ItemDatatableComponent, ItemFiltersComponent, ItemFormComponent } from '@modules/items/components';
import { environment } from '@env/environment';
import { ToastService } from '@core/services';
import { ItemDetailComponent } from '@modules/items/components/item-detail/item-detail.component';
import { LocationsService } from '@modules/locations/services';
import { CSVHandler } from '@shared/services/csv-handler.service';
import { LoadingComponent } from '@shared/components/loading/loading.component';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
  imports: [
    CommonModule,
    SharedModule,
    ItemDatatableComponent,
    ItemFormComponent,
    ItemFiltersComponent,
    LoadingComponent,
  ],
})
export class ItemsComponent implements OnInit {
  readonly title = 'Assets Management';
  // The component subscribe and fetch data from service state
  importAssetsURL = `${environment.backend?.url}/assets/import`;
  readonly itemsServiceState: Signal<ItemsServiceState> = this.itemsService.state.asReadonly();
  readonly csvHandlerState = this.csvHandler.getStates.asReadonly();
  ref: DynamicDialogRef | undefined;

  constructor(
    private readonly toastService: ToastService,
    private readonly titleService: Title,
    private readonly itemsService: ItemsService,
    private readonly confirmationService: ConfirmationService,
    private readonly locationsService: LocationsService,
    private readonly vendorsService: VendorsService,
    private readonly assetTypeService: AssetTypeService,
    private readonly dialogService: DialogService,
    private readonly csvHandler: CSVHandler<Item>,
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.itemsService.resetApiParams();

    // Get all vendors
    this.vendorsService.apiParams.page = {
      number: 0,
      size: 'all',
    };
    this.vendorsService.getAll().pipe(untilDestroyed(this)).subscribe();

    // Get all locations
    this.locationsService.apiParams.page = {
      number: 0,
      size: 'all',
    };
    this.locationsService.getAll().pipe(untilDestroyed(this)).subscribe();

    // Get all asset types
    this.assetTypeService.apiParams.page = {
      number: 0,
      size: 'all',
    };
    this.assetTypeService.fetchAssetTypes().pipe(untilDestroyed(this)).subscribe();

    this.configureCSVHandler();
  }

  private configureCSVHandler() {
    this.csvHandler.setApiUrl = '/assets';
    this.csvHandler.setDelimiter = ';';
    this.csvHandler.setColumns = [
      { title: 'TagId', field: 'tagId' },
      { title: 'Asset Type', value: (data: Item) => data.assetType?.name },
      { title: 'Vendor', value: (data: Item) => data.vendor?.name },
      { title: 'Location', value: (data: Item) => data.location?.name },
      { title: 'Info 1', field: 'info1' },
      { title: 'Info 2', field: 'info2' },
      { title: 'Info 3', field: 'info3' },
      {
        title: 'Created At',
        value: (data: Item) => dayjs(data.createdAt).format('MM/DD/YYYY, hh:mm:ss A'),
      },
      {
        title: 'Updated At',
        value: (data: Item) => dayjs(data.updatedAt).format('MM/DD/YYYY, hh:mm:ss A'),
      },
    ];
  }

  viewItem(item: Item) {
    this.itemsService.set('selectedItem', item);
    this.ref = this.dialogService.open(ItemDetailComponent, {
      header: 'Asset Details',
      styleClass: 'p-fluid',
      width: '75vw',
      modal: true,
      draggable: true,
    });
  }

  newItem() {
    this.itemsService.set('selectedItem', undefined);
    this.itemsService.set('formEditMode', false);
    this.openForm();
  }

  editItem(item: Item) {
    this.itemsService.set('selectedItem', item);
    this.itemsService.set('formEditMode', true);
    this.openForm();
  }

  onUpload(ev: any) {
    const message = ev.originalEvent.body.message;
    this.toastService.open({
      severity: 'success',
      summary: 'Asset Import',
      detail: message,
      life: 2000,
    });
    this.itemsService.fetchItems().pipe(untilDestroyed(this)).subscribe();
  }

  downloadCSV() {
    this.csvHandler.setTotalPage = this.itemsServiceState().total;
    this.csvHandler
      .generateContent(this.itemsServiceState().apiParams)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (csvBlob) => {
          if (!csvBlob) return;

          const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.csv`;
          this.csvHandler.downloadFile(csvBlob, fileName);
        },
      });
  }

  deleteItem(item: Item) {
    this.itemsService.set('selectedItem', undefined);
    this.confirmationService.confirm({
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to delete ?',
      accept: () => {
        this.itemsService
          .deleteItem(item.id as string)
          .pipe(untilDestroyed(this))
          .subscribe();
      },
    });
  }

  bulkDeleteItem(ids: string[]) {
    this.confirmationService.confirm({
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to delete the selected items?',
      accept: () => {
        this.itemsService.bulkDeleteItem(ids).pipe(untilDestroyed(this)).subscribe();
      },
    });
  }

  private openForm() {
    this.ref = this.dialogService.open(ItemFormComponent, {
      header: this.itemsServiceState().formEditMode ? 'Edit Asset' : 'New Asset',
      styleClass: 'p-fluid',
      width: '50vw',
      modal: true,
      draggable: true,
    });
  }
}
