import { Component, EventEmitter, OnInit, Output, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablePageEvent } from 'primeng/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@core/models';
import { LocationsService, LocationsServiceState } from '@modules/locations/services';
import { findKey } from 'lodash-es';
import { TimezoneService, ToastService } from '@core/services';
import { SharedModule } from '@shared/shared.module'; // dependent on utc plugin

const dayjs = require('dayjs');
const dayjsUTC = require('dayjs/plugin/utc');
const dayjsTZ = require('dayjs/plugin/timezone');
dayjs.extend(dayjsUTC); // use plugin
dayjs.extend(dayjsTZ); // use plugin

@UntilDestroy()
@Component({
  selector: 'app-location-datatable',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './location-datatable.component.html',
  styleUrls: ['./location-datatable.component.scss'],
})
export class LocationDatatableComponent implements OnInit {
  @Output() onNewLocation: EventEmitter<any> = new EventEmitter<Location>();
  @Output() onViewLocation: EventEmitter<any> = new EventEmitter<Location>();
  @Output() onEditLocation: EventEmitter<any> = new EventEmitter<Location>();
  @Output() onDeleteLocation: EventEmitter<any> = new EventEmitter<Location>();

  // The component subscribe and fetch data from service state
  readonly locationsServiceState: Signal<LocationsServiceState> = this.locationsService.state.asReadonly();
  relationSort: string[] = ['vendor'];

  skeletonHeaders = new Array(12).fill('_');
  skeletonLocations = new Array(this.locationsServiceState().paginatorState.rows).fill(1);

  constructor(
    public locationsService: LocationsService,
    private timezoneService: TimezoneService,
    protected toastService: ToastService,
  ) {}

  ngOnInit() {
    this.locationsService.resetPaginatorState();
    this.locationsService.getAll().pipe(untilDestroyed(this)).subscribe();
  }

  newLocation() {
    this.onNewLocation.emit();
  }

  viewLocation(location: Location) {
    this.onViewLocation.emit(location);
  }

  editLocation(location: Location) {
    this.onEditLocation.emit(location);
  }

  deleteLocation(location: Location) {
    this.onDeleteLocation.emit(location);
  }

  onSort(event: any) {
    if (event) {
      if (this.relationSort.includes(event.field)) {
        this.locationsService.apiParams.sort = {
          field: event.field,
          order: JSON.stringify({ name: event.order === 1 ? 'asc' : 'desc' }),
        };
      } else {
        this.locationsService.apiParams.sort = {
          field: event.field,
          order: event.order === 1 ? 'asc' : 'desc',
        };
      }
      this.locationsService.getAll().pipe(untilDestroyed(this)).subscribe();
    }
  }

  onPageChange(event: TablePageEvent | any) {
    const isPerPageChanged = event.rows != this.locationsServiceState().paginatorState.rows;

    this.locationsService.set('paginatorState', {
      first: isPerPageChanged ? 0 : event.first,
      rows: event.rows,
    });

    this.locationsService.apiParams.page = {
      number: isPerPageChanged ? 1 : event.page + 1,
      size: event.rows,
    };

    this.locationsService.getAll().pipe(untilDestroyed(this)).subscribe();
  }

  displayTimezone(timezone: string): string {
    if (!timezone) {
      return '';
    }

    return findKey(this.timezoneService.tz, (v) => v.toLowerCase() === timezone?.toLowerCase()) || '';
  }
}
