import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GlobalSettings } from 'src/app/configuration/pageSettings';

@Component({
  selector: 'dashboard-builder-save-dashboard-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss'],
})
export class SettingsDialogComponent {
  settings!: GlobalSettings;

  constructor(private dialogRef: MatDialogRef<SettingsDialogComponent>) {}

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }
}
