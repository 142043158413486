import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { navConfig } from '../../configuration/nav-bar-settings';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() openSidebar: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() resetSidebarWidth: EventEmitter<any> = new EventEmitter();
  isSidebarOpen: boolean = false;
  navItems: any = navConfig;

  ngOnInit() {
    this.resetSidebarWidth.emit();
    this.openSidebar.emit(this.isSidebarOpen);
  }

  openSlideBar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.openSidebar.emit(this.isSidebarOpen);
  }

  oneNavItemSelect(index: number) {
    for (let i = 0; i < this.navItems.length; i++) {
      this.navItems[i].selected = i === index;
    }
  }
}
