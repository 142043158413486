import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: SharedModule,
})
export class PlatformService {
  // Live updating the display type
  public isMobile;
  public isWeb;
  public isTablet;
  public isLandscape;

  private mobile = new BehaviorSubject<boolean>(false);
  private web = new BehaviorSubject<boolean>(false);
  private tablet = new BehaviorSubject<boolean>(false);
  private landscape = new BehaviorSubject<boolean>(false);

  constructor(
    private platformService: Platform,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web]).subscribe((data) => {
      this.mobile.next(data.breakpoints[Breakpoints.HandsetPortrait] || data.breakpoints[Breakpoints.HandsetLandscape]);
      this.web.next(data.breakpoints[Breakpoints.WebPortrait] || data.breakpoints[Breakpoints.WebLandscape]);
      this.tablet.next(data.breakpoints[Breakpoints.TabletPortrait] || data.breakpoints[Breakpoints.TabletLandscape]);
      this.landscape.next(
        data.breakpoints[Breakpoints.TabletLandscape] ||
          data.breakpoints[Breakpoints.WebLandscape] ||
          data.breakpoints[Breakpoints.HandsetLandscape],
      );
    });
    this.isMobile = this.mobile.asObservable();
    this.isWeb = this.web.asObservable();
    this.isTablet = this.tablet.asObservable();
    this.isLandscape = this.landscape.asObservable();
  }

  // Those functions always return the same value for each session --> No Observable
  public isAndroid() {
    return this.platformService.ANDROID;
  }

  public isBlink() {
    return this.platformService.BLINK;
  }

  public isEdge() {
    return this.platformService.EDGE;
  }

  public isFirefox() {
    return this.platformService.FIREFOX;
  }

  public isIOS() {
    return this.platformService.IOS;
  }

  public isSafari() {
    return this.platformService.SAFARI;
  }

  public isTrident() {
    return this.platformService.TRIDENT;
  }

  public isWebkit() {
    return this.platformService.WEBKIT;
  }
}
