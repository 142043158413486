import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, defer, EMPTY, finalize, retry, tap } from 'rxjs';
import { ToastService } from '@core/services/toast.service';
import { Router } from '@angular/router';

export const ACCESS_TOKEN_KEY = 'accessToken';
export const USER = 'user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl!: string;
  authenticated: boolean = false;
  accessToken: string = JSON.parse(sessionStorage.getItem(ACCESS_TOKEN_KEY) || 'null');
  logoutRetryCount = 5;

  constructor(
    private httpClient: HttpClient,
    private toastService: ToastService,
    private router: Router,
  ) {
    this.validateToken();
  }

  validateToken(): boolean | Promise<boolean> {
    if (!this.accessToken) {
      return (this.authenticated = false);
    } else {
      this.authenticated = true; // TODO: call API to validate the token on backend
      if (this.router.url === '/login') {
        return this.redirectAfterLogin();
      }
      return true;
    }
  }

  login(email: string, password: string) {
    return this.httpClient.post(`${environment.backend?.url}/auth/login`, { email, password }).pipe(
      tap((res: any) => {
        this.toastService.onHttpSuccess('Logged in');
        sessionStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(res.data.tokens));
        this.setUserSession(email, res.data);
        this.authenticated = true;
      }),
      catchError(() => {
        this.toastService.onHttpError('Failed to login');
        return EMPTY;
      }),
    );
  }

  logout() {
    this.authenticated = false;
    defer(() => this.httpClient.delete(`${environment.backend?.url}/auth/logout`))
      .pipe(
        retry(this.logoutRetryCount),
        finalize(() => {
          this.toastService.onHttpSuccess('Logged out');
          sessionStorage.removeItem(ACCESS_TOKEN_KEY);
          sessionStorage.removeItem(USER);
        }),
        catchError(() => {
          return EMPTY;
        }),
      )

      .subscribe(() => {
        this.router.navigateByUrl('/login');
      });
  }

  getSession() {
    return JSON.parse(sessionStorage.getItem(USER) || '{}');
  }

  redirectAfterLogin() {
    return this.router.navigateByUrl('/asset-management');
  }

  private setUserSession(email: string, data: { name: string; role: number }) {
    const userData = {
      email,
      ...data,
    };
    sessionStorage.setItem(USER, JSON.stringify(userData));
  }
}
