<div class="fixed z-50 left-0 top-0 bg-gray-500 bg-opacity-40 w-screen h-full flex justify-center items-center">
  <div class="bg-white bg-opacity-100 w-[75%] p-8 rounded-md">
    <p class="text-lg font-bold mb-2">
      <ng-container *ngIf="value <100; else completeTemplate"
        ><i class="pi pi-spin pi-spinner mr-1"></i>{{title}}{{titleSuffix}}</ng-container
      >
      <ng-template #completeTemplate>
        <span class="text-green-500"><i class="pi pi-check-circle mr-1"></i> {{completeTitle}}</span>
      </ng-template>
    </p>

    <p-progressBar class="w-full" [value]="value" [showValue]="false" />
  </div>
</div>
