<div class="flex flex-row space-x-4">

  <div class="flex p-input-icon-left">
    <i class="pi pi-search"></i>
    <input (keyup)="onNameChanged($event)"
           [(ngModel)]="filter.name"
           class="flex w-full"
           pInputText
           placeholder="Vendor name"
           type="text"/>
  </div>

  <!-----------------------------Filter Clear--------------------------------->
  <button (click)="onFilterReset()" *ngIf="resetButtonStatus"
          class="p-button p-ripple primary-500"
          icon="pi pi-refresh"
          label="Reset filter"
          pButton pRipple></button>
</div>
