<p-card [header]="title">

  <p-toolbar styleClass="mb-4">
    <app-user-filters></app-user-filters>
  </p-toolbar>

  <app-user-datatable (onChangePassword)="changePassword($event)"
                      (onDeleteUser)="deleteUser($event)"
                      (onEditUser)="editUser($event)"
                      (onNewUser)="newUser()"
                      (onViewUser)="viewUser($event)">
  </app-user-datatable>
</p-card>
