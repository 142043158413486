<form (ngSubmit)="onSubmit()" [formGroup]="locationForm" autocomplete="off" class="w-full mt-5">
  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="name" id="name" pInputText/>
            <label htmlFor="name">Location name*</label>
        </span>
    <p-message *ngIf="hasErrorAndTouched(locationForm.get('name'), 'required')"
               severity="error" text="*Location name is required."></p-message>

  </div>


  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="pic" id="pic" pInputText
            />
            <label htmlFor="pic">Contact Person</label>
        </span>
  </div>

  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="email" id="email" pInputText type="email"
            />
            <label htmlFor="email">Contact Email</label>
        </span>
    <p-message *ngIf="hasErrorAndTouched(locationForm.get('email'), 'email')"
               severity="error" text="*Invalid Email"></p-message>
  </div>

  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="city" id="city" pInputText type="city"
            />
            <label htmlFor="city">City</label>
        </span>
  </div>

  <div class="w-full mb-8">
    <span class="p-float-label">
                     <p-dropdown [filter]="true"
                                 [options]="timezones"
                                 [required]="true"
                                 formControlName="timezone"
                                 id="timezone"
                                 optionLabel="name"
                                 optionValue="value"
                     ></p-dropdown>
            <label htmlFor="vendor">Timezone*</label>
        </span>
    <p-message *ngIf="hasErrorAndTouched(locationForm.get('timezone'), 'required')"
               severity="error" text="*Timezone is required."></p-message>

  </div>

  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="postcode" id="postcode" pInputText
            />
            <label htmlFor="postcode">Postcode</label>
        </span>


  </div>

  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="phone" id="phone" pInputText
            />
            <label htmlFor="phone">Contact Phone</label>
        </span>


  </div>


  <div class="flex flex-row space-x-4">
    <button [disabled]="locationForm.invalid||locationForm.get('ipAddress')?.errors?.['asyncValidation']"
            class="p-button p-ripple p-component mt-3"
            label="Save"
            pButton pRipple type="submit"></button>
  </div>
</form>
