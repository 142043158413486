<div class="flex flex-col">
  <span class="font-bold text-lg">Tag information</span>
  <div class="p-4 space-y-3">
    <div class="flex">
      <div class="flex-1 font-semibold">
        Tag ID
      </div>
      <div class="flex-1">
        {{ itemsServiceState().selectedItem?.tagId }}
      </div>
    </div>
    <div class="flex">
      <div class="flex-1 font-semibold">
        Asset Type
      </div>
      <div class="flex-1">
        {{ itemsServiceState().selectedItem?.assetType?.name }}
      </div>
    </div>
    <div class="flex">
      <div class="flex-1 font-semibold">
        Info 1
      </div>
      <div class="flex-1">
        {{ itemsServiceState().selectedItem?.info1 }}
      </div>
    </div>
    <div class="flex">
      <div class="flex-1 font-semibold">
        Info 2
      </div>
      <div class="flex-1">
        {{ itemsServiceState().selectedItem?.info2 }}
      </div>
    </div>
    <div class="flex">
      <div class="flex-1 font-semibold">
        Info 3
      </div>
      <div class="flex-1">
        {{ itemsServiceState().selectedItem?.info3 }}
      </div>
    </div>
  </div>
</div>
