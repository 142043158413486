<p-table
  (onSort)="onSort($event)"
  [lazy]="true"
  [tableStyle]="{ 'min-width': '75rem' }"
  [value]="locationsServiceState().loading ? skeletonLocations : locationsServiceState().locations"
  styleClass="p-datatable-gridlines">public
  <!-- Headers -->
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="name" style="width:10%">Name
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th>City</th>
      <th>Timezone</th>
      <th>Postcode</th>
      <th>Contact Person</th>
      <th>Contact Phone</th>
      <th>Contact Email</th>
      <th>No.Assets</th>
      <th>Created At</th>
      <th>Updated At</th>
      <th>
        <div class="flex w-full justify-end">
          <button (click)="newLocation()"
                  class="p-button p-ripple primary-500"
                  icon="pi pi-plus"
                  label="New"
                  pButton pRipple>
          </button>
        </div>
      </th>
    </tr>
  </ng-template><!-- /Headers -->

  <!-- Body -->
  <ng-template let-location pTemplate="body">
    <!-- Skeleton locations & loading -->
    <tr *ngIf="locationsServiceState().loading">
      <ng-container *ngFor="let _ of skeletonHeaders">
        <td>
          <p-skeleton height="2rem"></p-skeleton>
        </td>
      </ng-container>
    </tr><!-- /End loading -->

    <tr *ngIf="!locationsServiceState().loading">
      <td class="pl-3"><i (cbOnSuccess)="toastService.onCopySuccess()" [cbContent]="location.id"
                          [pTooltip]="location.id" class="pi pi-copy hover:cursor-pointer"
                          ngxClipboard
                          tooltipPosition="right"></i> {{ location.name }}
      </td>
      <td>{{ location.city }}</td>
      <td>{{ displayTimezone(location.timezone) }}</td>
      <td>{{ location.postcode }}</td>
      <td>{{ location.pic }}</td>
      <td>{{ location.phone }}</td>
      <td>{{ location.email }}</td>
      <td style="text-align: center">
        <p-tag [style]="{'width': '4rem','font-weight': 'bold'}" [value]="location.assetsCount"></p-tag>
      </td>
      <td style="width: 230px;">{{ location.createdAt | date: 'MM/dd/YYYY, hh:mm:ss a' }}</td>
      <td style="width: 230px;">{{ location.updatedAt | date: 'MM/dd/YYYY, hh:mm:ss a' }}</td>
      <td>
        <div class="flex w-full justify-end space-x-4 px-4">
          <button (click)="editLocation(location)"
                  class="p-button-rounded p-button-secondary p-3 p-button-outlined mr-1"
                  icon="pi pi-pencil"
                  pButton
                  pTooltip="Edit"
                  tooltipPosition="bottom">
          </button>
          <button (click)="deleteLocation(location)"
                  class="p-button-rounded p-button-danger p-3 p-button-outlined"
                  icon="pi pi-trash"
                  pButton
                  pTooltip="Delete"
                  tooltipPosition="bottom">
          </button>
        </div>
      </td>
    </tr>
  </ng-template><!-- /Body -->
</p-table>

<div class="flex locations-center justify-end mt-3">
  <p-paginator (onPageChange)="onPageChange($event)"
               [first]="locationsServiceState().paginatorState.first"
               [pageLinkSize]=4
               [rowsPerPageOptions]="[10, 20, 50]"
               [rows]="locationsServiceState().paginatorState.rows"
               [showCurrentPageReport]="true"
               [showFirstLastIcon]="true"
               [showJumpToPageInput]="true"
               [showPageLinks]="true"
               [totalRecords]="locationsServiceState().total"
               currentPageReportTemplate="{first} - {last} of {totalRecords}">
  </p-paginator>
</div>
