import { Component, EventEmitter, OnInit, Output, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Vendor } from '@core/models';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TablePageEvent } from 'primeng/table';
import { SharedModule } from '@shared/shared.module';
import { VendorsService, VendorsServiceState } from '@modules/vendors/services';
import { ToastService } from '@core/services';

@UntilDestroy()
@Component({
  selector: 'app-vendor-datatable',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './vendor-datatable.component.html',
  styleUrls: ['./vendor-datatable.component.scss'],
})
export class VendorDatatableComponent implements OnInit {
  @Output() onNewVendor: EventEmitter<any> = new EventEmitter<Vendor>();
  @Output() onViewVendor: EventEmitter<any> = new EventEmitter<Vendor>();
  @Output() onEditVendor: EventEmitter<any> = new EventEmitter<Vendor>();
  @Output() onDeleteVendor: EventEmitter<any> = new EventEmitter<Vendor>();
  relationSorts: string[] = [];

  // The component subscribe and fetch data from service state
  readonly vendorsServiceState: Signal<VendorsServiceState> = this.vendorService.state.asReadonly();

  skeletonHeaders = new Array(8).fill('_');
  skeletonVendors = new Array(this.vendorsServiceState().paginatorState.rows).fill(1);

  constructor(
    public vendorService: VendorsService,
    protected toastService: ToastService,
  ) {}

  ngOnInit() {
    this.vendorService.resetPaginatorState();
    this.vendorService.getAll().pipe(untilDestroyed(this)).subscribe();
  }

  newVendor() {
    this.onNewVendor.emit();
  }

  viewVendor(item: Vendor) {
    this.onViewVendor.emit(item);
  }

  editVendor(item: Vendor) {
    this.onEditVendor.emit(item);
  }

  deleteVendor(item: Vendor) {
    this.onDeleteVendor.emit(item);
  }

  onSort(event: any) {
    if (event) {
      if (this.relationSorts.includes(event.field)) {
        this.vendorService.apiParams.sort = {
          field: event.field,
          order: JSON.stringify({ name: event.order === 1 ? 'asc' : 'desc' }),
        };
      } else {
        this.vendorService.apiParams.sort = {
          field: event.field,
          order: event.order === 1 ? 'asc' : 'desc',
        };
      }
      this.vendorService.getAll().pipe(untilDestroyed(this)).subscribe();
    }
  }

  onPageChange(event: TablePageEvent | any) {
    const isPerPageChanged = event.rows != this.vendorsServiceState().paginatorState.rows;

    this.vendorService.set('paginatorState', {
      first: isPerPageChanged ? 0 : event.first,
      rows: event.rows,
    });

    this.vendorService.apiParams.page = {
      number: isPerPageChanged ? 1 : event.page + 1,
      size: event.rows,
    };

    this.vendorService.getAll().pipe(untilDestroyed(this)).subscribe();
  }
}
