import { Injectable } from '@angular/core';
import { AbstractApiService } from '@core/services';
import { ScanHistory } from '@core/models';
import { environment } from '@env/environment';
import { catchError, tap, throwError } from 'rxjs';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';

const stringify = require('qs/lib/stringify');

@Injectable({
  providedIn: 'root',
})
export class ScanHistoriesApiService extends AbstractApiService<ScanHistory> {
  constructor() {
    super(`${environment.backend?.url}/scanHistories`);
  }

  exportToDEAM(filter: any) {
    this.toastService.open({
      severity: 'info',
      summary: 'SFTP Operation',
      detail: 'Preparing file to send!',
      life: 5000,
    });

    const params = new HttpParams({
      fromString: stringify(
        { filter },
        {
          skipNulls: true,
        },
      ),
    });

    return this.httpClient.post(`${this.baseUrl}/csv/export`, null, { params }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.toastService.open({
          severity: 'error',
          summary: 'SFTP Operation',
          detail: error.error?.message || 'Can not send file!',
          life: 2000,
        });
        return throwError(() => 'Something went wrong, please try again later.');
      }),
      tap(() => {
        this.toastService.clear();
        this.toastService.open({
          severity: 'success',
          summary: 'SFTP Operation',
          detail: 'File is send!',
          life: 2000,
        });
      }),
    );
  }

  downloadCSV(filter: any) {
    const params = new HttpParams({
      fromString: stringify(
        { filter },
        {
          skipNulls: true,
        },
      ),
    });

    return this.httpClient
      .get(`${this.baseUrl}/csv/download`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        tap((response: any) => {
          const contentDisposition = response.headers.get('Content-Disposition');
          let filename = 'download.csv'; // Default filename if no filename is provided in the header

          if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          const blob = new Blob([response.body], { type: 'text/csv' });
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = objectUrl;
          a.download = filename;
          a.click();

          URL.revokeObjectURL(objectUrl);
        }),
        catchError((error: HttpErrorResponse) => {
          this.toastService.open({
            severity: 'error',
            summary: 'CSV Download',
            detail: error.error?.message || 'Can not download file!',
            life: 2000,
          });
          return throwError(() => 'Something went wrong, please try again later.');
        }),
      );
  }
}
