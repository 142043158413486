import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validates if the confirmed password matches the original password.
 * Returns a validation error if the passwords do not match.
 * @returns A validator function that can be used in Angular forms.
 */
export function passwordMatchValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.parent?.value.password;
    if (password) {
      const confirmedPassword = control.value;

      if (password !== confirmedPassword) {
        return { passwordMismatch: true };
      }
    }
    return null;
  };
}
