<p-card [header]="title">

  <p-toolbar styleClass="mb-4">
    <app-staff-filters></app-staff-filters>
  </p-toolbar>

  <app-staff-datatable (onChangePasswordStaff)="changePassword($event)"
                       (onDeleteStaff)="deleteStaff($event)"
                       (onEditStaff)="editStaff($event)"
                       (onNewStaff)="newStaff()"
                       (onViewStaff)="viewStaff($event)">
  </app-staff-datatable>
</p-card>

<p-dialog
  [(visible)]="showNotDeletableDialog"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  header="Notice">
  <p>
    This staff has Scan History associated with it.<br>
    Please remove them first.
  </p>
  <p-footer>
    <button (click)="showNotDeletableDialog = false" label="OK" pButton type="button"></button>
  </p-footer>
</p-dialog>
