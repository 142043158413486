import { Component, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { PaginatorModule } from 'primeng/paginator';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { RippleModule } from 'primeng/ripple';
import { PasswordValidators } from 'ngx-validators';
import { passwordMatchValidator } from '@shared/validators';
import { User } from '@core/models';
import { DialogService } from 'primeng/dynamicdialog';
import { UsersService, UsersServiceState } from '@modules/users/services';
import { hasErrorAndTouched } from '@shared/form-controls.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-user-password-form',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    MessageModule,
    PaginatorModule,
    ReactiveFormsModule,
    RippleModule,
  ],
  templateUrl: './user-password-form.component.html',
  styleUrls: ['./user-password-form.component.scss'],
})
export class UserPasswordFormComponent {
  readonly userServiceState: Signal<UsersServiceState> = this.userService.state.asReadonly();
  readonly selectedUser: User | undefined = this.userServiceState().selectedUser;
  readonly hasErrorAndTouched = hasErrorAndTouched;

  userForm = this.fb.group({
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        PasswordValidators.alphabeticalCharacterRule(1),
        PasswordValidators.digitCharacterRule(1),
        PasswordValidators.lowercaseCharacterRule(1),
        PasswordValidators.uppercaseCharacterRule(1),
        PasswordValidators.specialCharacterRule(1),
      ],
    ],
    confirmedPassword: new FormControl('', {
      validators: [Validators.required, passwordMatchValidator()],
    }),
  });

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    public dialogService: DialogService,
  ) {}

  onPasswordChange() {
    this.userForm.controls.confirmedPassword.updateValueAndValidity();
  }

  onSubmit() {
    const passwords: any = { ...this.userForm.value };
    this.userService
      .changPassword(this.selectedUser?.id as string, passwords)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogService.dialogComponentRefMap.forEach((dialog) => {
          dialog.destroy();
        });
      });
  }
}
