/**
 * @copyright
 * Copyright(c) 2021 SICK AG
 */

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoggingService {
  logError(...message: Array<unknown>): void {
    // eslint-disable-next-line no-console
    console.error('LoggingService: ', ...message);
  }
}
