import { Environment } from './environment-model';

export const environment: Environment = {
  production: false,
  oidc: {
    // ! Change this to your own client id
    clientId: 'dyson-tnt',
    issuer: 'https://id.test.sick.com/auth/realms/sickservices',
    responseType: 'code',
    scope: 'openid profile email',
    redirectUri: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
    logoutUrl: `${window.location.origin}`,
  },
  backend: {
    url: '//api.dyson-tnt.dev-cloud.sick.com/api',
    assetUrl: '//dyson-tnt.dev-cloud.sick.com',
    apiKey: '',
  },
  digitalServiceCatalog: {
    catalogUrlPrefix: '',
    subscriptionApiUrl: '',
    serviceIds: {
      basic: '',
    },
  },
  notificationManagement: {
    backendUrl: '',
    prefix: '',
  },
  roleManagement: {
    backendUrl: '',
    iamUrl: '',
  },
};
