import { Component, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemsService, ItemsServiceState } from '@modules/items/services';

@Component({
  selector: 'app-vendor-info',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vendor-info.component.html',
  styleUrls: ['./vendor-info.component.scss'],
})
export class VendorInfoComponent {
  readonly itemsServiceState: Signal<ItemsServiceState> = this.itemsService.state.asReadonly();

  constructor(private itemsService: ItemsService) {}
}
