import { Injectable } from '@angular/core';
import { GetAllAPIParams, SignalsSimpleStoreService } from '@core/services';
import { ScanHistory } from '@core/models';
import { assign, cloneDeep } from 'lodash-es';
import { delay, tap } from 'rxjs';
import { ScanHistoriesApiService } from '@modules/scan-histories/services/scan-histories-api.service';
import { TablePageEvent } from 'primeng/table';

interface ScanHistoryAPIParams extends GetAllAPIParams {
  filter: {
    tagId: string | undefined;
    info1: string | undefined;
    vendorId: string | undefined;
    locationId: string | undefined;
    staffId: string | undefined;
    fromDate: string | undefined;
    toDate: string | undefined;
    assetTypeId: string | undefined;
  };
}

export interface ScanHistoryServiceState {
  scanHistories: ScanHistory[];
  selectedScanHistory?: ScanHistory;
  total: number;
  loading: boolean;
  formEditMode: boolean;
  apiParams: ScanHistoryAPIParams;
  paginatorState: TablePageEvent;
}

@Injectable({
  providedIn: 'root',
})
export class ScanHistoriesService extends SignalsSimpleStoreService<ScanHistoryServiceState> {
  defaultEndDate!: any;
  defaultStartDate!: any;

  initialApiParams: ScanHistoryAPIParams = assign({}, this.scanHistoriesApiService.getAllParams, {
    filter: {
      tagId: undefined,
      info1: undefined,
      vendorId: undefined,
      locationId: undefined,
      staffId: undefined,
      fromDate: this.defaultStartDate,
      toDate: this.defaultEndDate,
      assetTypeId: undefined,
    },
    sort: {
      field: 'checkedTime',
      order: 'desc',
    },
  });

  initialPaginatorState: TablePageEvent = assign(
    {},
    {
      first: 0,
      rows: 10,
    },
  );

  apiParams!: ScanHistoryAPIParams;
  paginatorState!: TablePageEvent;

  constructor(private scanHistoriesApiService: ScanHistoriesApiService) {
    super();
    this.resetApiParams();
    this.resetPaginatorState();
  }

  resetApiParams() {
    this.apiParams = cloneDeep(this.initialApiParams);
    this.set('apiParams', this.apiParams);
  }

  resetPaginatorState() {
    this.paginatorState = cloneDeep(this.initialPaginatorState);
    this.set('paginatorState', this.paginatorState);
  }

  getAll() {
    this.set('loading', true);
    return this.scanHistoriesApiService
      .getAll({
        params: this.apiParams,
      })
      .pipe(
        delay(600), // Add some delay to show loading effect
        tap((res: any) => {
          this.set('loading', false);
          this.set('scanHistories', res.data);
          this.set('total', res.meta?.total);
        }),
      );
  }

  exportToDEAM() {
    return this.scanHistoriesApiService.exportToDEAM(this.apiParams.filter);
  }

  downloadCSV() {
    return this.scanHistoriesApiService.downloadCSV(this.apiParams.filter);
  }
}
