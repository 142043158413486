import { Component, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemsService, ItemsServiceState } from '@modules/items/services';

@Component({
  selector: 'app-tag-info',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tag-info.component.html',
  styleUrls: ['./tag-info.component.scss'],
})
export class TagInfoComponent {
  readonly itemsServiceState: Signal<ItemsServiceState> = this.itemsService.state.asReadonly();

  constructor(private itemsService: ItemsService) {}
}
