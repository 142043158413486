import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { OverlayComponent } from '@shared/components';

@Component({
  selector: 'app-notification-center',
  standalone: true,
  imports: [CommonModule, SharedModule, OverlayComponent],
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent {}
