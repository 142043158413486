import { Injectable } from '@angular/core';
import { GetAllAPIParams, SignalsSimpleStoreService } from '@core/services';
import { assign, cloneDeep } from 'lodash-es';
import { VendorsApiService } from '@modules/vendors/services/vendors-api.service';
import { Vendor } from '@core/models';
import { concatMap, delay, tap } from 'rxjs';
import { TablePageEvent } from 'primeng/table';

interface VendorsAPIParams extends GetAllAPIParams {
  filter: {
    name: string | undefined;
  };
}

export interface VendorsServiceState {
  vendors: Vendor[];
  selectedVendor?: Vendor;
  total: number;
  loading: boolean;
  formEditMode: boolean;
  apiParams: VendorsAPIParams;
  paginatorState: TablePageEvent;
}

@Injectable({
  providedIn: 'root',
})
export class VendorsService extends SignalsSimpleStoreService<VendorsServiceState> {
  initialApiParams: VendorsAPIParams = assign({}, this.vendorApiService.getAllParams, {
    filter: {
      name: undefined,
    },
  });
  initialPaginatorState: TablePageEvent = assign(
    {},
    {
      first: 0,
      rows: 10,
    },
  );

  apiParams!: VendorsAPIParams;
  paginatorState!: TablePageEvent;

  constructor(private vendorApiService: VendorsApiService) {
    super();
    this.resetApiParams();
    this.resetPaginatorState();
  }

  resetApiParams() {
    this.apiParams = cloneDeep(this.initialApiParams);
    this.set('apiParams', this.apiParams);
  }

  resetPaginatorState() {
    this.paginatorState = cloneDeep(this.initialPaginatorState);
    this.set('paginatorState', this.paginatorState);
  }

  getAll() {
    this.set('loading', true);
    return this.vendorApiService
      .getAll({
        params: this.apiParams,
      })
      .pipe(
        delay(600), // Add some delay to show loading effect
        tap((res: any) => {
          this.set('loading', false);
          this.set('vendors', res.data);
          this.set('total', res.meta?.total);
        }),
      );
  }

  new(payload: Vendor) {
    return this.vendorApiService.create(payload).pipe(concatMap(() => this.getAll()));
  }

  edit(id: string | number, payload: Vendor) {
    return this.vendorApiService.update(id, payload).pipe(concatMap(() => this.getAll()));
  }

  delete(id: string | number) {
    return this.vendorApiService.delete(id).pipe(concatMap(() => this.getAll()));
  }
}
