import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@core/services';
import { ToastService } from '@core/services/toast.service';

/**
 * Interceptor to handle server errors in HTTP requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ServerErrorInterceptor implements HttpInterceptor {
  protected toastService: ToastService = inject(ToastService);

  constructor(private _authService: AuthService) {}

  /**
   * Intercepts the HTTP request and handles server errors.
   * @param req The HTTP request.
   * @param next The next HTTP handler.
   * @returns An observable of the HTTP event.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludeUrls = ['/login', '/logout'];

    /** Check if Session Storage have Ignore Error Intercept. */
    const ignoreErrorIntercept = sessionStorage.getItem('ignoreErrorIntercept') === 'true';
    ignoreErrorIntercept && sessionStorage.removeItem('ignoreErrorIntercept');

    const isExclude = excludeUrls.some((keyword) => req.url.includes(keyword));

    if (ignoreErrorIntercept || isExclude) return next.handle(req);

    return next.handle(req).pipe(
      catchError((err: HttpEvent<any>) => {
        if (!(err instanceof HttpErrorResponse)) return throwError(() => err);

        const errorStatus = err?.status || 0;

        if (errorStatus === 401) {
          return this.handleErrorStatus401(err);
          // } else if (errorStatus >= 400 && errorStatus < 500) {
          // Here we can extend to handle other error code such as 403, 404 ... etc
          // return this.handleErrorStatus4xx(err)
        } else if (errorStatus >= 500) {
          return this.handleErrorStatus5xx(err);
        } else if (errorStatus === 403) {
          return this.handleErrorStatus403(err);
        } else {
          return throwError(() => err);
        }
      }),
    );
  }

  /**
   * Handles the 401 error status.
   * @param error The HTTP error response.
   * @returns An empty observable.
   */
  private handleErrorStatus401(error: HttpErrorResponse): Observable<never> {
    this._authService.logout();

    return EMPTY;
  }

  /**
   * Handles the 403 error status.
   * @param error The HTTP error response.
   * @returns An empty observable.
   */
  private handleErrorStatus403(error: HttpErrorResponse): Observable<never> {
    console.error(error);
    this.toastService.onGetError('You do not have permission to perform this action.');

    return EMPTY;
  }

  /**
   * Handles the 5xx error status.
   * @param error The HTTP error response.
   * @returns An empty observable.
   */
  private handleErrorStatus5xx(error: HttpErrorResponse): Observable<never> {
    console.error(error);

    return EMPTY;
  }
}
