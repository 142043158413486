<div class="bg-secondary-500 h-full w-full z-30 space-between flex flex-col duration-300">
  <nav>
    <a *ngFor="let navItem of navItems" class="flex flex-row flex-grow z-30 h-14 w-full items-center"
       routerLink="{{'/'+navItem.url}}"
       routerLinkActive="active-route">
      <app-nav-item (click)="oneNavItemSelect(navItem.index)" [expanded]="isSidebarOpen" [navIcon]="navItem.icon"
                    [navLabel]="navItem.label"
                    [pTooltip]="!isSidebarOpen ? navItem.label : ''" [selected]="navItem.selected"
                    class="flex flex-grow w-full">
      </app-nav-item>
    </a>
  </nav>
  <div class="mt-auto items-center justify-end flex  border-t border-white">
    <button (click)='openSlideBar()'
            class="flex justify-center items-center h-[48px] w-[48px] hover:bg-primary-400 duration-300">
      <i [ngClass]="isSidebarOpen ? 'rotate-0' : 'rotate-180'" class="pi pi-angle-double-left duration-300"
         style="color: white;font-size: 1.5rem"></i>
    </button>
  </div>
</div>
