<p-table
  [paginator]="false"
  [tableStyle]="{ 'min-width': '75rem' }"
  [value]="usersServiceState().loading ? skeletonUsers : usersServiceState().users"
  styleClass="p-datatable-gridlines">
  <!-- Headers -->
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="name">Name
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="email">Email
        <p-sortIcon field="email"></p-sortIcon>
      </th>
      <th>Role</th>
      <th>Phone</th>
      <th>Department</th>
      <th>Updated At</th>
      <th>
        <div class="flex w-full justify-end">
          <button (click)="newUser()"
                  class="p-button p-ripple primary-500"
                  icon="pi pi-plus"
                  label="New"
                  pButton pRipple>
          </button>
        </div>
      </th>
    </tr>
  </ng-template><!-- /Headers -->

  <!-- Body -->
  <ng-template let-user pTemplate="body">
    <!-- Skeleton users & loading -->
    <tr *ngIf="usersServiceState().loading">
      <ng-container *ngFor="let _ of skeletonHeaders">
        <td class="pl-3">
          <p-skeleton height="2rem"></p-skeleton>
        </td>
      </ng-container>
    </tr><!-- /End loading -->

    <tr *ngIf="!usersServiceState().loading">
      <td>
        <i (cbOnSuccess)="toastService.onCopySuccess()" [cbContent]="user.id" [pTooltip]="user.id"
           class="pi pi-copy hover:cursor-pointer text-xl"
           ngxClipboard
           tooltipPosition="right">
        </i>
        {{ user.name }}
      </td>
      <td class="whitespace-nowrap">{{ user.email }}</td>
      <td class="whitespace-nowrap">{{ user.roles }}</td>
      <td class="whitespace-nowrap">{{ user.phone }}</td>
      <td class="whitespace-nowrap">{{ user.department }}</td>
      <td class="whitespace-nowrap">{{ user.updatedAt | date: 'MM/dd/YYYY, hh:mm:ss a' }}</td>
      <td>
        <div class="flex w-full justify-end space-x-4 px-4">
          <button (click)="changePassword(user)" class="p-button-rounded p-button-secondary p-3 p-button-outlined"
                  icon="pi pi-lock"
                  pButton
                  pTooltip="Change Password"
                  tooltipPosition="bottom"></button>
          <button (click)="editUser(user)"
                  class="p-button-rounded p-button-secondary p-3 p-button-outlined mr-1"
                  icon="pi pi-pencil"
                  pButton
                  pTooltip="Edit"
                  tooltipPosition="bottom">
          </button>
          <button (click)="deleteUser(user)"
                  class="p-button-rounded p-button-danger p-3 p-button-outlined"
                  icon="pi pi-trash"
                  pButton
                  pTooltip="Delete"
                  tooltipPosition="bottom">
          </button>
        </div>
      </td>
    </tr>
  </ng-template><!-- /Body -->
</p-table>

<div class="flex vendors-center justify-end mt-3">
  <p-paginator (onPageChange)="onPageChange($event)"
               [first]="usersServiceState().paginatorState.first"
               [pageLinkSize]=4
               [rowsPerPageOptions]="[10, 20, 50]"
               [rows]="usersServiceState().paginatorState.rows"
               [showCurrentPageReport]="true"
               [showFirstLastIcon]="true"
               [showJumpToPageInput]="true"
               [showPageLinks]="true"
               [totalRecords]="usersServiceState().total"
               currentPageReportTemplate="{first} - {last} of {totalRecords}">
  </p-paginator>
</div>
