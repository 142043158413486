import { Injectable } from '@angular/core';
import { Item } from '@core/models';
import { AbstractApiService } from '@core/services';
import { environment } from '@env/environment';
import { catchError, EMPTY, tap, throwError } from 'rxjs';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';

const stringify = require('qs/lib/stringify');

@Injectable({
  providedIn: 'root',
})
export class ItemsApiService extends AbstractApiService<Item> {
  constructor() {
    // Set your API endpoint here
    super(`${environment.backend?.url}/assets`);
  }

  bulkDelete(ids: string[]) {
    return this.httpClient.delete(`${this.baseUrl}/delete/bulk`, { body: { ids } }).pipe(
      tap((_: any) => {
        this.toastService.onDeleteSuccess('Items deleted successfully');
      }),
      catchError((error) => {
        this.toastService.onDeleteError(error.message);
        return EMPTY;
      }),
    );
  }

  downloadCSV(filter: any) {
    const params = new HttpParams({
      fromString: stringify(
        { filter },
        {
          skipNulls: true,
        },
      ),
    });

    return this.httpClient
      .get(`${this.baseUrl}/csv/download`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        tap((response: any) => {
          console.log('wtttt');

          const contentDisposition = response.headers.get('Content-Disposition');
          let filename = 'download.csv'; // Default filename if no filename is provided in the header

          if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          const blob = new Blob([response.body], { type: 'text/csv' });
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = objectUrl;
          a.download = filename;
          a.click();

          URL.revokeObjectURL(objectUrl);
        }),
        catchError((error: HttpErrorResponse) => {
          this.toastService.open({
            severity: 'error',
            summary: 'CSV Download',
            detail: error.error?.message || 'Can not download file!',
            life: 2000,
          });
          return throwError(() => 'Something went wrong, please try again later.');
        }),
      );
  }
}
