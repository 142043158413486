import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { BaseHeaderComponent } from './base-header/base-header.component';
import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';
import { SubscriptionMissingComponent } from './subscription-missing/subscription-missing.component';
import { EntryComponent } from './entry/entry.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavItemComponent } from './sidebar/nav-item/nav-item.component';
import { UserActionComponent } from './base-header/user-action/user-action.component';
import { UserInforComponent } from './base-header/user-action/user-infor/user-infor.component';
import { OverlayComponent } from '@shared/components';
import { NotificationCenterComponent } from './base-header/user-action/notification-center/notification-center.component';
import { LoadingComponent } from '../shared/components/loading/loading.component';

const COMPONENTS = [
  SubscriptionMissingComponent,
  SettingsDialogComponent,
  BaseHeaderComponent,
  EntryComponent,
  SidebarComponent,
  NavItemComponent,
  UserActionComponent,
  UserInforComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [SharedModule, OverlayComponent, NotificationCenterComponent, LoadingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
