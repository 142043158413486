<p-table
  (onSort)="onSort($event)"
  [(selection)]="_selectedItems"
  [lazy]="true"
  [tableStyle]="{ 'min-width': '30rem' }"
  [value]="itemsServiceState().loading ? skeletonItems : itemsServiceState().items"
  styleClass="p-datatable-gridlines">
  <!-- Headers -->
  <ng-template pTemplate="header">
    <tr>
      <th class="w-[5px]">
        <div class="flex justify-center items-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </div>
      </th>
      <th>Tag Id</th>
      <th>Vendor</th>
      <th>Location</th>
      <th>Asset Type</th>
      <th pSortableColumn="createdAt">Created At
        <p-sortIcon field="createdAt"></p-sortIcon>
      </th>
      <th pSortableColumn="updatedAt">Updated At
        <p-sortIcon field="updatedAt"></p-sortIcon>
      </th>
      <th class="whitespace-nowrap">Info 1</th>
      <th class="whitespace-nowrap">Info 2</th>
      <th class="whitespace-nowrap">Info 3</th>
      <th class=" justify-end">
        <div class="flex justify-end items-center">
          <button (click)="newItem()"
                  class="p-button p-ripple primary-500"
                  icon="pi pi-plus"
                  label="New"
                  pButton
                  pRipple style="margin-right: 1rem">
          </button>

          <button (click)="bulkDelete()" [disabled]="!_selectedItems || !_selectedItems.length"
                  class="p-button-danger h-full aspect-[1/1]" icon="pi pi-trash" pButton pRipple
                  pTooltip="Bulk Delete"
                  tooltipPosition="right"></button>

        </div>
      </th>
    </tr>
  </ng-template><!-- /Headers -->

  <!-- Body -->
  <ng-template let-item pTemplate="body">
    <!-- Skeleton items & loading -->
    <tr *ngIf="itemsServiceState().loading">
      <ng-container *ngFor="let _ of skeletonHeaders">
        <td class="pl-3">
          <p-skeleton height="2rem"></p-skeleton>
        </td>
      </ng-container>
    </tr><!-- /End loading -->

    <tr *ngIf="!itemsServiceState().loading">
      <td>
        <div class="flex justify-center items-center">
          <p-tableCheckbox [value]="item"></p-tableCheckbox>
        </div>
      </td>
      <td class="whitespace-nowrap">
        <i (cbOnSuccess)="toastService.onCopySuccess()"
           [cbContent]="item.tagId"
           [pTooltip]="item.id"
           class="pi pi-copy hover:cursor-pointer" ngxClipboard
           tooltipPosition="right"></i>
        {{ item.tagId }}
      </td>
      <td class="whitespace-nowrap">{{ item.vendor?.name }}</td>
      <td class="whitespace-nowrap">{{ item.location?.name }}</td>
      <td>{{ item.assetType?.name }}</td>
      <td>{{ item.createdAt | date: 'MM/dd/YYYY, hh:mm:ss a' }}</td>
      <td>{{ item.updatedAt | date: 'MM/dd/YYYY, hh:mm:ss a' }}</td>
      <td class="whitespace-nowrap">{{ item.info1 }}</td>
      <td class="whitespace-nowrap">{{ item.info2 }}</td>
      <td class="whitespace-nowrap">{{ item.info3 }}</td>
      <td>
        <div class="flex w-full justify-end space-x-4 px-4">
          <button (click)="viewItem(item)"
                  class="p-button-rounded p-button-primary p-3 p-button-outlined mr-1"
                  icon="pi pi-search"
                  pButton
                  pTooltip="View"
                  tooltipPosition="bottom">
          </button>
          <button (click)="editItem(item)"
                  class="p-button-rounded p-button-secondary p-3 p-button-outlined mr-1"
                  icon="pi pi-pencil"
                  pButton
                  pTooltip="Edit"
                  tooltipPosition="bottom">
          </button>
          <button (click)="deleteItem(item)"
                  class="p-button-rounded p-button-danger p-3 p-button-outlined"
                  icon="pi pi-trash"
                  pButton
                  pTooltip="Delete"
                  tooltipPosition="bottom">
          </button>
        </div>
      </td>
    </tr>
  </ng-template><!-- /Body -->
</p-table>

<div class="flex items-center justify-end mt-3">
  <p-paginator (onPageChange)="onPageChange($event)"
               [first]="itemsServiceState().paginatorState.first"
               [pageLinkSize]=4
               [rowsPerPageOptions]="[10, 20, 50,100]"
               [rows]="itemsServiceState().paginatorState.rows"
               [showCurrentPageReport]="true"
               [showFirstLastIcon]="true"
               [showJumpToPageInput]="true"
               [showPageLinks]="true"
               [totalRecords]="itemsServiceState().total"
               currentPageReportTemplate="{first} - {last} of {totalRecords}">
  </p-paginator>
</div>
