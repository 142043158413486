import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { API_KEY, AWS_ENDPOINT_PREFIX } from 'src/app/configuration/endpoints';

/**
 * Interceptor for adding OAuth authentication to HTTP requests.
 */
@Injectable()
export class OAuthInterceptor implements HttpInterceptor {
  constructor(private authService: OAuthService) {}

  /**
   * Intercepts the HTTP request and adds the OAuth token to the headers if necessary.
   * @param request - The HTTP request to intercept.
   * @param next - The next HTTP handler in the chain.
   * @returns An observable of the HTTP event.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const auth =
      request.url.startsWith(environment.digitalServiceCatalog?.subscriptionApiUrl || 'XXX') ||
      request.url.startsWith(AWS_ENDPOINT_PREFIX || 'XXX');

    const token = this.authService.getAccessToken();

    if (auth && token) {
      return of(token).pipe(
        mergeMap((accessToken) =>
          next.handle(
            request.clone({
              headers: request.headers
                .set('Authorization', `Bearer ${accessToken}`)
                .set('x-bearer', token)
                .set('x-user', '123')
                .set('x-api-key', API_KEY || '')
                .set('Access-Control-Allow-Origin', '*'),
              withCredentials: false,
            }),
          ),
        ),
      );
    }
    return next.handle(request);
  }
}
