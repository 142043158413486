export interface NavConfigItem {
  index: number;
  icon: string;
  selected: boolean;
  label: string;
  url: string;
}

export const navConfig: NavConfigItem[] = [
  {
    index: 0,
    icon: 'pi-th-large',
    selected: true,
    label: 'Asset Management',
    url: 'asset-management',
  },
  { index: 1, icon: 'pi-history', selected: false, label: 'Scan History', url: 'scan-histories' },
  { index: 2, icon: 'pi-building', selected: false, label: 'Vendor Management', url: 'vendor-management' },
  { index: 3, icon: 'pi-map-marker', selected: false, label: 'Location Management', url: 'location-management' },
  { index: 4, icon: 'pi-id-card ', selected: false, label: 'Staff Management', url: 'staff-management' },
  { index: 5, icon: 'pi-users', selected: false, label: 'User Management', url: 'users' },
];
