import { Injectable } from '@angular/core';
import { concatMap, delay, tap } from 'rxjs';
import { User } from '@core/models';
import { assign, cloneDeep } from 'lodash-es';
import { GetAllAPIParams, SignalsSimpleStoreService } from '@core/services';
import { UsersApiService } from './users-api.service';
import { TablePageEvent } from 'primeng/table';

interface UsersAPIParams extends GetAllAPIParams {
  filter: {
    email: string;
  };
}

export interface UsersServiceState {
  users: User[];
  selectedUser?: User;
  total: number;
  loading: boolean;
  formEditMode: boolean;
  apiParams: UsersAPIParams;
  paginatorState: TablePageEvent;
}

@Injectable({
  providedIn: 'root',
})
export class UsersService extends SignalsSimpleStoreService<UsersServiceState> {
  initialApiParams: UsersAPIParams = assign({}, this.usersApiService.getAllParams, {
    filter: {
      email: '',
    },
  });
  initialPaginatorState: TablePageEvent = assign(
    {},
    {
      first: 0,
      rows: 10,
    },
  );

  apiParams!: UsersAPIParams;
  paginatorState!: TablePageEvent;

  constructor(private usersApiService: UsersApiService) {
    super();
    this.resetApiParams();
    this.resetPaginatorState();
  }

  resetApiParams() {
    this.apiParams = cloneDeep(this.initialApiParams);
    this.set('apiParams', this.apiParams);
  }

  resetPaginatorState() {
    this.paginatorState = cloneDeep(this.initialPaginatorState);
    this.set('paginatorState', this.paginatorState);
  }

  changPassword(id: string, passwords: { password: string; confirmPassword: string }) {
    return this.usersApiService.changePassword(id, passwords).pipe();
  }

  fetchUsers() {
    this.set('loading', true);
    return this.usersApiService
      .getAll({
        params: this.apiParams,
      })
      .pipe(
        delay(600), // Add some delay to show loading effect
        tap((res: any) => {
          this.set('loading', false);
          this.set('users', res.data);
          this.set('total', res.meta?.total);
        }),
      );
  }

  newUser(payload: User) {
    return this.usersApiService.create(payload).pipe(concatMap(() => this.fetchUsers()));
  }

  editUser(id: string | number, payload: User) {
    return this.usersApiService.update(id, payload).pipe(concatMap(() => this.fetchUsers()));
  }

  deleteUser(id: string | number) {
    return this.usersApiService.delete(id).pipe(concatMap(() => this.fetchUsers()));
  }
}
