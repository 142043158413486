import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, EMPTY, Observable, tap, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { ToastService } from '@core/services/toast.service';
const stringify = require('qs/lib/stringify');

interface RequestOptions {
  observe: 'response' | 'body' | 'events';
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  params?:
    | HttpParams
    | GetAllAPIParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
}

export interface GetAllAPIParams {
  filter: {
    [key: string]: string | number | boolean | undefined | ReadonlyArray<string | number | boolean>;
  };
  page: {
    number: number;
    size: number | 'all';
  };
  sort: {
    field: string | undefined;
    order: 'asc' | 'desc' | undefined | string;
  };
}

interface ListResponse<T> {
  data: T[];
  meta: {
    message?: string;
    page: number;
    perPage: number;
    total: number;
  };
}

/**
 * @ngdoc interface
 * In the AbstractApiService, the generic type T represents the entity class.
 *
 * In the ItemApiService, you extend AbstractApiService<Item> to create a service specifically for the Item entity.
 */
export abstract class AbstractApiService<T> {
  getAllParams: GetAllAPIParams = {
    filter: {},
    page: {
      number: 1,
      size: 10,
    },
    sort: {
      field: undefined,
      order: undefined,
    },
  };
  protected toastService: ToastService = inject(ToastService);
  protected httpClient: HttpClient = inject(HttpClient);

  constructor(protected baseUrl: string) {}

  getAll(requestOptions?: Partial<RequestOptions>, isThrowError?: boolean): Observable<Partial<ListResponse<T>>> {
    return this.request<Array<T>>('GET', {
      ...requestOptions,
      observe: 'response',
    }).pipe(
      tap((_: any) => {
        // this.toastService.onGetSuccess()
      }),
      catchError((error) => {
        this.toastService.onGetError();
        return isThrowError ? throwError(() => error) : EMPTY;
      }),
      map((res: any) => res.body as Partial<ListResponse<T>>),
    );
  }

  getById(id: number | string, requestOptions?: Partial<RequestOptions>): Observable<T> {
    return this.request('GET', requestOptions, undefined, id).pipe(
      tap((_: any) => {
        // this.toastService.onGetSuccess()
      }),
      catchError(() => {
        this.toastService.onGetError();
        return EMPTY;
      }),
    );
  }

  create(body: Partial<T>, requestOptions?: Partial<RequestOptions>): Observable<T> {
    return this.request('POST', requestOptions, body).pipe(
      tap((_: any) => {
        this.toastService.onCreateSuccess();
      }),
      catchError((err) => {
        this.toastService.onCreateError(err.error.message || 'Create failed!');
        return EMPTY;
      }),
    );
  }

  update(
    id: number | string,
    body: Partial<T>,
    requestOptions?: Partial<RequestOptions>,
    method: 'PUT' | 'PATCH' = 'PATCH',
  ): Observable<T> {
    return this.request(method, requestOptions, body, id).pipe(
      tap((_: any) => {
        this.toastService.onUpdateSuccess();
      }),
      catchError((err) => {
        this.toastService.onUpdateError(err.error.message || 'Update failed!');
        return EMPTY;
      }),
    );
  }

  delete(id: number | string, requestOptions?: Partial<RequestOptions>): Observable<void> {
    return this.request('DELETE', requestOptions, undefined, id).pipe(
      tap((_: any) => {
        this.toastService.onDeleteSuccess();
      }),
      catchError(() => {
        this.toastService.onDeleteError();
        return EMPTY;
      }),
    );
  }

  protected request<T>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    options?: Partial<RequestOptions>,
    body?: any,
    path?: number | string,
  ): Observable<T> {
    const params = new HttpParams({
      fromString: stringify(options?.params, {
        skipNulls: true,
      }),
    });

    return this.httpClient.request(method, this.getUrl(path), {
      params,
      body: body,
      observe: options?.observe || 'body',
    });
  }

  private getUrl(path?: string | number) {
    return path?.toString() ? `${this.baseUrl}/${path}` : this.baseUrl;
  }
}
