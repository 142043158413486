import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '@angular/cdk/layout';
import { PlatformModule } from '@angular/cdk/platform';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SliderModule } from 'primeng/slider';
import { TabViewModule } from 'primeng/tabview';
import { HttpClientModule } from '@angular/common/http';
import { RippleModule } from 'primeng/ripple';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { SkeletonModule } from 'primeng/skeleton';
import { TreeTableModule } from 'primeng/treetable';
import { AccordionModule } from 'primeng/accordion';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PaginatorModule } from 'primeng/paginator';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { IpAddressValidatorDirective } from './directives/ip-address-validator.directive';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToolbarModule } from 'primeng/toolbar';
import { DialogModule } from 'primeng/dialog';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { PasswordModule } from 'primeng/password';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { ImageModule } from 'primeng/image';
import { GalleriaModule } from 'primeng/galleria';
import { EditorModule } from 'primeng/editor';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { ScrollTopModule } from 'primeng/scrolltop';
import { StyleClassModule } from 'primeng/styleclass';
import { BadgeModule } from 'primeng/badge';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { ClipboardModule } from 'ngx-clipboard';
import { ProgressBarModule } from 'primeng/progressbar';

const ANGULAR_MODULES = [
  CommonModule,
  LayoutModule,
  PlatformModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
];

const PRIMENG_MODULES = [
  EditorModule,
  CardModule,
  DividerModule,
  TabViewModule,
  RippleModule,
  BlockUIModule,
  ButtonModule,
  SliderModule,
  AvatarModule,
  DropdownModule,
  InputTextModule,
  InputNumberModule,
  ScrollPanelModule,
  ProgressSpinnerModule,
  TableModule,
  ConfirmDialogModule,
  RadioButtonModule,
  ToolbarModule,
  DialogModule,
  TagModule,
  TooltipModule,
  CalendarModule,
  SkeletonModule,
  TreeTableModule,
  PasswordModule,
  AccordionModule,
  ToggleButtonModule,
  InputSwitchModule,
  InputTextareaModule,
  PaginatorModule,
  FileUploadModule,
  ToastModule,
  MessageModule,
  MessagesModule,
  DynamicDialogModule,
  PanelModule,
  ImageModule,
  GalleriaModule,
  TagModule,
  SelectButtonModule,
  SidebarModule,
  DynamicDialogModule,
  AvatarModule,
  ScrollTopModule,
  StyleClassModule,
  BadgeModule,
  OverlayPanelModule,
  MultiSelectModule,
  ProgressBarModule,
];

@NgModule({
  declarations: [DebounceClickDirective, IpAddressValidatorDirective],
  imports: [...ANGULAR_MODULES, ...PRIMENG_MODULES, RouterModule, NgxMaskDirective, NgxMaskPipe, ClipboardModule],
  exports: [
    ...ANGULAR_MODULES,
    ...PRIMENG_MODULES,

    TranslateModule,
    ClipboardModule,
    // Directives
    DebounceClickDirective,
    NgxMaskDirective,
    IpAddressValidatorDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [provideNgxMask(), IpAddressValidatorDirective],
})
export class SharedModule {}
