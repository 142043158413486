import { Injectable } from '@angular/core';
import { GetAllAPIParams, SignalsSimpleStoreService } from '@core/services';
import { Location } from '@core/models';
import { assign, cloneDeep } from 'lodash-es';
import { LocationsApiService } from './locations-api.service';
import { concatMap, delay, tap } from 'rxjs';
import { TablePageEvent } from 'primeng/table';

interface LocationsAPIParams extends GetAllAPIParams {
  filter: {
    name: string | undefined;
    vendorId: string | undefined;
  };
}

export interface LocationsServiceState {
  locations: Location[];
  selectedLocation?: Location;
  total: number;
  loading: boolean;
  formEditMode: boolean;
  apiParams: LocationsAPIParams;
  paginatorState: TablePageEvent;
}

@Injectable({
  providedIn: 'root',
})
export class LocationsService extends SignalsSimpleStoreService<LocationsServiceState> {
  initialApiParams: LocationsAPIParams = assign({}, this.locationApiService.getAllParams, {
    filter: {
      name: undefined,
      vendorId: undefined,
    },
  });
  initialPaginatorState: TablePageEvent = assign(
    {},
    {
      first: 0,
      rows: 10,
    },
  );

  apiParams!: LocationsAPIParams;
  paginatorState!: TablePageEvent;

  constructor(private locationApiService: LocationsApiService) {
    super();
    this.resetApiParams();
    this.resetPaginatorState();
  }

  resetApiParams() {
    this.apiParams = cloneDeep(this.initialApiParams);
    this.set('apiParams', this.apiParams);
  }

  resetPaginatorState() {
    this.paginatorState = cloneDeep(this.initialPaginatorState);
    this.set('paginatorState', this.paginatorState);
  }

  getAll() {
    this.set('loading', true);
    return this.locationApiService
      .getAll({
        params: this.apiParams,
      })
      .pipe(
        delay(600), // Add some delay to show loading effect
        tap((res: any) => {
          this.set('loading', false);
          this.set('locations', res.data);
          this.set('total', res.meta?.total);
        }),
      );
  }

  new(payload: Location) {
    return this.locationApiService.create(payload).pipe(concatMap(() => this.getAll()));
  }

  edit(id: string | number, payload: Location) {
    return this.locationApiService.update(id, payload).pipe(concatMap(() => this.getAll()));
  }

  delete(id: string | number) {
    return this.locationApiService.delete(id).pipe(concatMap(() => this.getAll()));
  }
}
