<div
  (mouseleave)="isOpenDialog=false"
  (mouseover)="isOpenDialog=true"
  class="relative flex items-center justify-center cursor-pointer"
>
  <ng-content select="[iconContent]"></ng-content>
  <div *ngIf="isOpenDialog"
       class="absolute top-10 right-0 p-5 bg-white shadow-top-right">
    <div class="absolute -top-1 right-1.5 w-[20px] h-[20px] bg-white rotate-45"></div>
    <div class="absolute -top-5 -right-5 w-[100px] h-[100px] opacity-0"></div>
    <ng-content select="[overlayContent]"></ng-content>
  </div>
</div>
