<div class="flex flex-row justify-between items-center w-full h-20 px-3 shadow">
  <div class="flex flex-row items-center ml-2">
    <img alt="Logo" class="h-10" src="assets/images/dyson-logo.svg">
  </div>
  <div class="flex flex-row items-center">
    <span class="hidden sm:inline  text-neutral-500 text-xl items-center">{{ appName }} {{ appVersion }}</span>
    <div class="flex items-center border-l-[2px] justify-between border-neutral-400 h-6 mx-6"></div>
    <div class="flex justify-center items-center space-x-3 mr-3">
      <app-user-action></app-user-action>
    </div>
  </div>
</div>
