import { AfterViewInit, Component, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { merge, Subject, tap } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VendorsService, VendorsServiceState } from '@modules/vendors/services';
import { identity, keys, pickBy } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'app-vendor-filters',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './vendor-filters.component.html',
  styleUrls: ['./vendor-filters.component.scss'],
})
export class VendorFiltersComponent implements AfterViewInit {
  readonly vendorsServiceState: Signal<VendorsServiceState> = this.vendorsService.state.asReadonly();
  filter: any = this.vendorsServiceState().apiParams.filter;

  $filterNameChanged: Subject<string> = new Subject();
  $filterReset: Subject<boolean> = new Subject();
  $filterChanged: Subject<any> = new Subject();

  constructor(private vendorsService: VendorsService) {}

  get resetButtonStatus(): boolean {
    return keys(pickBy(this.vendorsService.apiParams.filter, identity)).length > 0;
  }

  ngAfterViewInit() {
    this.onFilterReset();
    merge(
      this.$filterNameChanged.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap((value: string) => {
          this.vendorsService.apiParams.filter.name = value.trim();
        }),
      ),
      this.$filterReset,
      this.$filterChanged,
    )
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.vendorsService.apiParams.page.number = 1;
          this.vendorsService.resetPaginatorState();
        }),
        switchMap(() => this.vendorsService.getAll()),
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onNameChanged(ev: any) {
    this.$filterNameChanged.next(ev.target.value);
  }

  onFilterReset() {
    this.filter = {}; // Force reset filter
    this.vendorsService.resetApiParams();
    this.$filterReset.next(true);
  }
}
