import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AppInitializerService, PlatformService, ToastService } from '@core/services';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const PRIMENG_SERVICES = [DialogService, MessageService, ConfirmationService, ToastService];

@NgModule({
  providers: [
    ...PRIMENG_SERVICES,

    AppInitializerService,
    PlatformService,

    //  TODO: Uncomment this when we have a license services
    // LicensingService,
    // LicenseGuard,
  ],
  imports: [BrowserModule, BrowserAnimationsModule],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
