<form (ngSubmit)="onSubmit()" [formGroup]="userForm" autocomplete="off" class="w-full mt-5">

  <div class="w-full mb-8">
    <div class="p-float-label">
      <input class="w-full" formControlName="name" pInputText/>
      <label htmlFor="name">Name *</label>
    </div>
    <p-message *ngIf="hasErrorAndTouched(userForm.get('name'), 'required')"
               severity="error" text="This field is required."></p-message>
  </div>

  <div class="w-full mb-8">
    <div class="p-float-label">
      <input class="w-full" formControlName="email" pInputText/>
      <label htmlFor="email">Email *</label>
    </div>
    <p-message *ngIf="hasErrorAndTouched(userForm.get('email'), 'required')"
               severity="error" text="This field is required."></p-message>
    <p-message *ngIf="hasErrorAndTouched(userForm.get('email'), 'normalEmailRule')"
               severity="error" text="Not a valid email."></p-message>
    <p-message *ngIf="hasErrorAndTouched(userForm.get('email'), 'duplicated')"
               severity="error" text="* Not Available"></p-message>
  </div>

  <span class="p-float-label mb-8">
    <p-multiSelect [options]="rolesServiceState().roles" formControlName="roles" inputId="float-label"
                   optionLabel="value" optionValue="value"></p-multiSelect>
    <label htmlFor="float-label">Roles</label>
</span>

  <div class="w-full mb-8">
    <div class="p-float-label">
      <input class="w-full" formControlName="phone" pInputText/>
      <label htmlFor="phone">Phone</label>
    </div>
  </div>

  <div class="w-full mb-8">
    <div class="p-float-label">
      <input class="w-full" formControlName="department" pInputText/>
      <label htmlFor="department">Department</label>
    </div>
  </div>

  <div *ngIf="!isEditMode" class="w-full mb-8">
    <div class="p-float-label">
      <input class="w-full" formControlName="password" pInputText type="password"/>
      <label htmlFor="password">Password *</label>
    </div>
    <p-message *ngIf="hasErrorAndTouched(userForm.get('password'), 'required')"
               severity="error" text="This field is required."></p-message>
    <p-message *ngIf="hasErrorAndTouched(userForm.get('password'), 'minLength')
                      || hasErrorAndTouched(userForm.get('password'), 'alphabeticalCharacterRule')
                      || hasErrorAndTouched(userForm.get('password'), 'digitCharacterRule')
                      || hasErrorAndTouched(userForm.get('password'), 'lowercaseCharacterRule')
                      || hasErrorAndTouched(userForm.get('password'), 'uppercaseCharacterRule')
                      || hasErrorAndTouched(userForm.get('password'), 'specialCharacterRule')"
               severity="error" text="Password must contain at least 8 characters including lowercase, uppercase alphabet, number and 1 special characters "></p-message>
  </div>

  <div *ngIf="!isEditMode" class="w-full mb-8">
    <div class="p-float-label">
      <input class="w-full" formControlName="confirmPassword" pInputText type="password"/>
      <label htmlFor="confirmPassword">Confirm Password *</label>
    </div>
    <p-message *ngIf="hasErrorAndTouched(userForm.get('confirmPassword'), 'required')"
               severity="error" text="This field is required."></p-message>
    <p-message *ngIf="hasErrorAndTouched(userForm.get('confirmPassword'), 'mismatchedPasswords')"
               severity="error" text="Confirm password must be same as password"></p-message>
  </div>

  <div class="flex flex-row space-x-4">
    <button [disabled]="disableSubmitForm"
            class="p-button p-ripple p-component mt-3"
            label="Save"
            pButton pRipple type="submit"></button>
  </div>
</form>
