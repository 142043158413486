import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CoreModule } from '@core/core.module';
import { AppInitializerService } from '@core/services';

// Components
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';

// Misc
import { SharedModule } from '@shared/shared.module';

import { AppRoutingModule } from './app.routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { InternationalizationModule } from './internationalization/internationalization.module';

import { OAuthModule } from 'angular-oauth2-oidc';
import { NgxEchartsModule } from 'ngx-echarts';
import { LoginComponent } from '@modules/auth/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';
import { JWTInterceptor, ServerErrorInterceptor, TimezoneInterceptor } from '@core/interceptors';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Components
    ComponentsModule,
    SharedModule,
    CoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ClipboardModule,
    // Auth
    OAuthModule.forRoot(),

    InternationalizationModule.forRoot({ locale_id: 'en-US' }), // init with default language: en-US
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),

    NgOptimizedImage,

    // Pages
    LoginComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimezoneInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInit: { initialize: () => never }) => () => appInit.initialize(),
      multi: true,
      deps: [AppInitializerService],
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
