import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-missing',
  templateUrl: './subscription-missing.component.html',
  styleUrls: ['./subscription-missing.component.scss'],
})
export class SubscriptionMissingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
