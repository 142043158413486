<div *ngIf="!expanded"
     class="flex  w-full z-30 h-14 items-center justify-around hover:bg-primary-400">
  <i class="{{'pi ' +navIcon}}" style="color: white;font-size: 1.3rem"></i>
</div>

<div *ngIf="expanded"
     class="flex flex-row w-full z-30 h-14 items-center justify-between hover:bg-primary-400 px-4">
  <div class="flex justify-center items-center">
    <i class="{{ 'mr-4 pi ' + navIcon }}" style="color: white; font-size: 1.3rem"></i>
    <span class="text-white font-extrabold transition-opacity duration-300 whitespace-nowrap">{{ navLabel }}</span>
  </div>
  <i [ngClass]="selected ? 'rotate-0' : 'rotate-90'" class="pi pi-angle-right duration-100"
     style="color: white;font-size: 1.3rem"></i>
</div>
