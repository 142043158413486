import { Injectable } from '@angular/core';
import { concatMap, finalize, from, map, Observable, of, range, reduce } from 'rxjs';
import { AbstractApiService, GetAllAPIParams, SignalsSimpleStoreService } from '@core/services';
import { environment } from '@env/environment';

export type ColumnType<T> = { title: string; field?: string; value?: (data: T) => string | number | undefined };

export interface CsvHandlerState {
  loading: boolean;
  progressNumber: number;
}

@Injectable({
  providedIn: 'root',
})
export class CSVHandler<T> extends AbstractApiService<T> {
  private size = 1000;
  private totalPage = 0;
  private numberRange = range(0, this.totalPage);
  private delimiter = ',';
  private columns: ColumnType<T>[] = [];

  constructor(private readonly storeService: SignalsSimpleStoreService<CsvHandlerState>) {
    super('');
    this.storeService.set('loading', false);
    this.storeService.set('progressNumber', 0);
  }

  set setApiUrl(url: string) {
    this.baseUrl = environment.backend?.url + url;
  }

  set setSize(size: number) {
    this.size = size;
  }

  set setTotalPage(totalItems: number) {
    this.totalPage = Math.ceil(totalItems / this.size);
    this.numberRange = range(1, this.totalPage);
  }

  set setDelimiter(delimiter: string) {
    this.delimiter = delimiter;
  }

  set setColumns(columns: ColumnType<T>[]) {
    this.columns = columns;
  }

  get getStates() {
    return this.storeService.state;
  }

  private setProgressProps(progressNumber: number, loading: boolean) {
    this.storeService.set('progressNumber', progressNumber);

    if (loading) {
      this.storeService.set('loading', loading);
    } else {
      // Delay for friendly UX of progress bar
      setTimeout(() => {
        this.storeService.set('loading', loading);
      }, 2000);
    }
  }

  generateContent(params: GetAllAPIParams): Observable<any> {
    if (!this.baseUrl) return of();

    sessionStorage.setItem('ignoreErrorIntercept', 'true');
    this.setProgressProps(0, true);

    return this.numberRange.pipe(
      concatMap((number) => {
        const percent = Math.floor(((number - 1) / this.totalPage) * 100);
        this.storeService.set('progressNumber', percent);

        return this.fetchItemsInChunk({ ...params, page: { number, size: this.size } });
      }),
      concatMap((res: any) => from((res.data as any[]) || [])),
      reduce(
        (acc, data) => {
          const dataString =
            this.columns
              .map(({ field, value }) => (value ? value(data) : (data as any)[field as string]) || '')
              .join(this.delimiter) + '\n';

          return (acc += dataString);
        },
        this.columns.map((column) => column.title).join(this.delimiter) + '\n',
      ),
      map((csvContent) => {
        return new Blob([`SEP=${this.delimiter} \n` + csvContent], { type: 'text/csv;charset=utf-8;' });
      }),
      finalize(() => {
        this.setProgressProps(100, false);
        sessionStorage.removeItem('ignoreErrorIntercept');
      }),
    );
  }

  downloadFile(csvBlob: Blob, fileName: string) {
    const a = document.createElement('a');
    a.style.visibility = 'hidden';
    const url = URL.createObjectURL(csvBlob);
    a.href = url;
    a.download = fileName;

    a.click();

    URL.revokeObjectURL(url);
    a.remove();
  }

  private fetchItemsInChunk(params: GetAllAPIParams) {
    return this.getAll({ params }, true);
  }
}
