import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@core/services';
import { hasErrorAndTouched } from '@shared/form-controls.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import appPackage from 'package.json';

/**
 * Represents the LoginComponent class.
 * This component is responsible for handling the login functionality.
 */
@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class LoginComponent implements OnInit {
  readonly hasErrorAndTouched = hasErrorAndTouched;
  appName = appPackage.title;
  appVersion = `v${appPackage.version}`;
  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  /**
   * Constructs a new instance of the LoginComponent class.
   * @param fb - The FormBuilder instance used for creating the login form.
   * @param authService - The AuthService instance used for handling authentication.
   */
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
  ) {}

  /**
   * Gets a boolean value indicating whether the submit form button should be disabled.
   * @returns A boolean value indicating whether the submit form button should be disabled.
   */
  get disableSubmitForm(): boolean {
    return this.loginForm.invalid;
  }

  /**
   * Handles the form submission event.
   * Calls the login method of the AuthService to perform the login operation.
   * Subscribes to the login method's observable and redirects the user after successful login.
   */
  onSubmit() {
    const { email, password } = this.loginForm.value;

    this.authService
      .login(email as string, password as string)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.authService.redirectAfterLogin());
  }

  ngOnInit(): void {
    if (this.authService.authenticated) {
      this.authService.redirectAfterLogin();
    }
  }
}
