import { Component, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { ScanHistoriesService, ScanHistoryServiceState } from '@modules/scan-histories/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ItemsService, ItemsServiceState } from '@modules/items/services';

@UntilDestroy()
@Component({
  selector: 'app-scan-history',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './scan-history.component.html',
  styleUrls: ['./scan-history.component.scss'],
})
export class ScanHistoryComponent implements OnInit {
  readonly scanHistoryServiceState: Signal<ScanHistoryServiceState> = this.scanHistoryService.state.asReadonly();
  readonly itemsServiceState: Signal<ItemsServiceState> = this.itemsService.state.asReadonly();
  first: number = 0;
  rows: number = 4;

  skeletonHeaders = new Array(5).fill('_');
  skeletonScanHistories = new Array(this.rows).fill(1);

  constructor(
    private scanHistoryService: ScanHistoriesService,
    private itemsService: ItemsService,
  ) {}

  ngOnInit() {
    this.scanHistoryService.apiParams.filter.tagId = this.itemsServiceState().selectedItem?.tagId;
    this.scanHistoryService.apiParams.page.size = this.rows;
    this.scanHistoryService.apiParams.filter.toDate = undefined;
    this.scanHistoryService.apiParams.filter.fromDate = undefined;
    this.scanHistoryService.apiParams.page.number = 1;
    this.scanHistoryService.getAll().pipe(untilDestroyed(this)).subscribe();
  }
}
