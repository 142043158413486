import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * Directive that adds a debounce functionality to a click event.
 */
@Directive({
  selector: '[appDebounceClick]',
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  /** The debounce time in milliseconds. Default value is 300 milliseconds. */
  @Input() debounceTime = 300;
  /** Event emitter for the debounced click event. */
  @Output() debounceClick = new EventEmitter();

  private clicks = new Subject();
  private subscription!: Subscription;

  constructor() {}

  /**
   * Initializes the directive.
   * Sets up the subscription to the debounced click event.
   */
  ngOnInit() {
    this.subscription = this.clicks
      .pipe(debounceTime(this.debounceTime))
      .subscribe((e: any) => this.debounceClick.emit(e));
  }

  /**
   * Cleans up the directive.
   * Unsubscribes from the debounced click event subscription.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Event listener for the click event.
   * Prevents the default click behavior and stops event propagation.
   * Emits the click event to the debounced click event emitter.
   * @param event - The click event object.
   */
  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
