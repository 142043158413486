import { Injectable } from '@angular/core';
import { GetAllAPIParams, SignalsSimpleStoreService } from '@core/services';
import { AssetType } from '@core/models';
import { assign, cloneDeep } from 'lodash-es';
import { AssetTypeApiService } from '@modules/items/services';
import { delay, tap } from 'rxjs';
import { TablePageEvent } from 'primeng/table';

interface AssetTypesAPIParams extends GetAllAPIParams {}

export interface AssetTypesServiceState {
  types: AssetType[];
  selectedAssetType?: AssetType;
  total: number;
  loading: boolean;
  apiParams: AssetTypesAPIParams;
  paginatorState: TablePageEvent;
}

@Injectable({
  providedIn: 'root',
})
export class AssetTypeService extends SignalsSimpleStoreService<AssetTypesServiceState> {
  initialApiParams: AssetTypesAPIParams = assign({}, this.itemsApiService.getAllParams, {
    page: {
      number: 1,
      size: 'all',
    },
  });
  initialPaginatorState: TablePageEvent = assign(
    {},
    {
      first: 0,
      rows: 10,
    },
  );

  apiParams!: AssetTypesAPIParams;
  paginatorState!: TablePageEvent;

  constructor(private itemsApiService: AssetTypeApiService) {
    super();
    this.resetApiParams();
    this.resetPaginatorState();
  }

  resetApiParams() {
    this.apiParams = cloneDeep(this.initialApiParams);
    this.set('apiParams', this.apiParams);
  }

  resetPaginatorState() {
    this.paginatorState = cloneDeep(this.initialPaginatorState);
    this.set('paginatorState', this.paginatorState);
  }

  fetchAssetTypes() {
    this.set('loading', true);
    return this.itemsApiService
      .getAll({
        params: this.apiParams,
      })
      .pipe(
        delay(600), // Add some delay to show loading effect
        tap((res: any) => {
          this.set('loading', false);
          this.set('types', res.data);
          this.set('total', res.meta?.total);
        }),
      );
  }
}
