import { Injectable } from '@angular/core';
import { concatMap, delay, tap } from 'rxjs';
import { Item } from '@core/models';
import { assign, cloneDeep } from 'lodash-es';
import { GetAllAPIParams, SignalsSimpleStoreService } from '@core/services';
import { ItemsApiService } from './items-api.service';
import { TablePageEvent } from 'primeng/table';

interface ItemsAPIParams extends GetAllAPIParams {
  filter: {
    tagId: string | undefined;
    info1: string | undefined;
    vendorId: string | undefined;
    locationId: string | undefined;
    assetTypeId: string | undefined;
  };
}

export interface ItemsServiceState {
  items: Item[];
  selectedItem?: Item;
  selectedItems?: Item[];
  total: number;
  loading: boolean;
  formEditMode: boolean;
  apiParams: ItemsAPIParams;
  paginatorState: TablePageEvent;
}

@Injectable({
  providedIn: 'root',
})
export class ItemsService extends SignalsSimpleStoreService<ItemsServiceState> {
  initialApiParams: ItemsAPIParams = assign({}, this.itemsApiService.getAllParams, {
    filter: {
      tagId: undefined,
      info1: undefined,
      vendorId: undefined,
      locationId: undefined,
      assetTypeId: undefined,
    },
    sort: {
      field: 'updatedAt',
      order: 'desc',
    },
  });
  initialPaginatorState: TablePageEvent = assign(
    {},
    {
      first: 0,
      rows: 10,
    },
  );

  apiParams!: ItemsAPIParams;
  paginatorState!: TablePageEvent;

  constructor(private itemsApiService: ItemsApiService) {
    super();
    this.resetApiParams();
    this.resetPaginatorState();
  }

  resetApiParams() {
    this.apiParams = cloneDeep(this.initialApiParams);
    this.set('apiParams', this.apiParams);
  }

  resetPaginatorState() {
    this.paginatorState = cloneDeep(this.initialPaginatorState);
    this.set('paginatorState', this.paginatorState);
  }

  fetchItems() {
    this.set('loading', true);
    return this.itemsApiService
      .getAll({
        params: this.apiParams,
      })
      .pipe(
        delay(600), // Add some delay to show loading effect
        tap((res: any) => {
          this.set('loading', false);
          this.set('items', res.data);
          this.set('total', res.meta?.total);
        }),
      );
  }

  newItem(payload: Item) {
    return this.itemsApiService.create(payload).pipe(concatMap(() => this.fetchItems()));
  }

  editItem(id: string | number, payload: Item) {
    return this.itemsApiService.update(id, payload).pipe(concatMap(() => this.fetchItems()));
  }

  deleteItem(id: string | number) {
    return this.itemsApiService.delete(id).pipe(concatMap(() => this.fetchItems()));
  }

  bulkDeleteItem(ids: string[]) {
    return this.itemsApiService.bulkDelete(ids).pipe(concatMap(() => this.fetchItems()));
  }

  downloadCSV() {
    return this.itemsApiService.downloadCSV(this.apiParams.filter);
  }
}
