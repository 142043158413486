<form (ngSubmit)="onSubmit()" [formGroup]="userForm" class="w-full mt-5">
  <div class="w-full mb-8">
      <span class="text-lg font-bold">
        User Email:
      </span>
    <span class="text-lg">
        {{ selectedStaff?.email }}
      </span>
  </div>

  <div class="w-full mb-8">
      <span class="p-float-label">
          <input (input)="onPasswordChange()" class="w-full"
                 formControlName="password"
                 id="password" pInputText
                 type="password"
          />
          <label htmlFor="password">Password*</label>
      </span>
    <p-message *ngIf="hasErrorAndTouched(userForm.get('password'), 'required')"
               severity="error" text="*Password is required."></p-message>
    <p-message *ngIf="hasErrorAndTouched(userForm.get('password'), 'minLength')
                      || hasErrorAndTouched(userForm.get('password'), 'alphabeticalCharacterRule')
                      || hasErrorAndTouched(userForm.get('password'), 'digitCharacterRule')
                      || hasErrorAndTouched(userForm.get('password'), 'lowercaseCharacterRule')
                      || hasErrorAndTouched(userForm.get('password'), 'uppercaseCharacterRule')
                      || hasErrorAndTouched(userForm.get('password'), 'specialCharacterRule')"
               severity="error"
               text="Password must contain at least 8 characters including lowercase, uppercase alphabet, number and 1 special characters "></p-message>
  </div>

  <div class="w-full mb-8">
      <span class="p-float-label">
          <input class="w-full" formControlName="confirmedPassword"
                 id="confirmedpassword"
                 pInputText type="password"
          />
          <label htmlFor="confirmedpassword">Confirmed Password*</label>
      </span>
    <p-message *ngIf="hasErrorAndTouched(userForm.get('password'), 'required')"
               severity="error" text="*Confirmed assword is required."></p-message>
    <p-message *ngIf="userForm.get('confirmedPassword')?.errors?.['passwordMismatch']"
               severity="error" text="**Passwords not identical."></p-message>
  </div>

  <div class="flex flex-row space-x-4">
    <button [disabled]="userForm.invalid||userForm.get('password')?.errors?.['passwordMismatch']"
            class="p-button p-ripple p-component mt-3"
            label="Save"
            pButton pRipple type="submit"></button>
  </div>
</form>
