<p-card [header]="title">
  <p-toolbar styleClass="mb-4">
    <app-vendor-filters></app-vendor-filters>
  </p-toolbar>

  <app-vendor-datatable (onDeleteVendor)="deleteVendor($event)"
                        (onEditVendor)="editVendor($event)"
                        (onNewVendor)="newVendor()"
                        (onViewVendor)="viewVendor($event)">
  </app-vendor-datatable>

  <p-dialog
    header="Notice"
    [(visible)]="showNotDeletableDialog"
    [modal]="true"
    [draggable]="false"
    [resizable]="false">
    <p>
      This vendor has assets, locations or staffs associated with it.<br>
      Please remove them first.
    </p>
    <p-footer>
      <button pButton type="button" label="OK" (click)="showNotDeletableDialog = false"></button>
    </p-footer>
  </p-dialog>
</p-card>
