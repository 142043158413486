import { Injectable } from '@angular/core';
import { AbstractApiService } from '@core/services';
import { environment } from '@env/environment';
import { Role } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class RolesApiService extends AbstractApiService<Role> {
  constructor() {
    // Set your API endpoint here
    super(`${environment.backend?.url}/roles`);
  }

  // Additional methods specific to UserApiService if needed
  // For example, you may want to add methods for specific business logic related to users.
}
