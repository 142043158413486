import { Component } from '@angular/core';
import { AuthService } from '@core/services';

@Component({
  selector: 'app-user-infor',
  templateUrl: './user-infor.component.html',
  styleUrls: ['./user-infor.component.scss'],
})
export class UserInforComponent {
  constructor(protected authService: AuthService) {}

  logout() {
    this.authService.logout();
  }

  getUserName() {
    const session = this.authService.getSession();
    const name = session ? session.name : undefined;
    if (!name) return '';
    const characters = name.split(' ');
    const initials = characters.map((c: string) => c.charAt(0)).join('');
    return initials.toUpperCase();
  }
}
