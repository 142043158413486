import { inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AuthService } from '@core/services';

/**
 * Checks if the user is authenticated and returns a URL tree for navigation.
 * If the user is authenticated, it returns `true`.
 * If the user is not authenticated, it creates a URL tree for the login page with a redirect URL.
 * @returns `true` if the user is authenticated, otherwise a URL tree for the login page.
 */
export const authGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.authenticated) {
    return true;
  }

  /**
   * Set our navigation extras object
   * that contains our global query params and fragment
   */
  const navigationExtras: NavigationExtras = {
    queryParams: {
      redirectUrl: authService.redirectUrl,
    },
  };

  return router.createUrlTree(['/login'], navigationExtras);
};
