<div class="flex flex-row flex-wrap w-fit gap-4 items-center">

  <div class="flex p-input-icon-left">
    <i class="pi pi-search"></i>
    <input (keyup)="onTagIdChanged($event)"
           [(ngModel)]="filter.tagId"
           class="flex w-full"
           pInputText
           placeholder="Filter by Tag ID"
           type="text"/>
  </div>

  <div class="flex p-input-icon-left">
    <i class="pi pi-search"></i>
    <input (keyup)="onInfo1Changed($event)"
           [(ngModel)]="filter.info1"
           class="flex w-full"
           pInputText
           placeholder="Filter by Info 1"
           type="text"/>
  </div>

  <!--  ------------------Vendors filter dropdown------------------->
  <span class="p-float-label">
    <p-dropdown (onChange)="changeVendor($event)"
                [(ngModel)]="filter.vendorId"
                [filter]="true"
                [options]="vendorsServiceState().vendors"
                [showClear]="true"
                filterBy="name"
                inputId="vendor"
                optionLabel="name"
                placeholder="Select a Vendor">
    </p-dropdown>

    <label class="top-0" htmlFor="vendor">Vendor</label>
  </span>

  <!--  ------------------Location filter dropdown------------------->
  <span class="p-float-label">
    <p-dropdown
      (onChange)="changeLocation($event)"
      [(ngModel)]="filter.locationId"
      [filter]="true"
      [options]="locationsServiceState().locations"
      [showClear]="true"
      filterBy="name"
      inputId="location-label"
      optionLabel="name"
      placeholder="Select a Location"
    >
    </p-dropdown>
    <label htmlFor="location-label">Location</label>
  </span>

  <!-----------------------------Filter for Asset Type--------------------------------->
  <span class="p-float-label">
    <p-dropdown (onChange)="changeType($event)"
                [(ngModel)]="filter.assetTypeId"
                [filter]="true" [options]="assetTypesServiceState().types" [showClear]="true" filterBy="name"
                inputId="type-label"
                optionLabel="name" placeholder="Select an Asset type">
      <ng-template let-category pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ category.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
    <label htmlFor="type-label">Asset Type</label>
  </span>
  <!-----------------------------Filter Clear--------------------------------->
  <button (click)="onFilterReset()"
          *ngIf="resetButtonStatus"
          class="p-button p-ripple primary-500"
          icon="pi pi-refresh"
          label="Reset filter"
          pButton pRipple></button>
</div>
