import { Component, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorDatatableComponent, VendorFiltersComponent, VendorFormComponent } from '@modules/vendors/components';
import { Title } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { Vendor } from '@core/models';
import { SharedModule } from '@shared/shared.module';
import { VendorsService, VendorsServiceState } from '@modules/vendors/services/vendors.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@UntilDestroy()
@Component({
  selector: 'app-vendors',
  standalone: true,
  imports: [CommonModule, SharedModule, VendorDatatableComponent, VendorFormComponent, VendorFiltersComponent],
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss'],
})
export class VendorsComponent implements OnInit {
  readonly title = 'Vendor Management';
  // The component subscribe and fetch data from service state
  readonly vendorServiceState: Signal<VendorsServiceState> = this.vendorService.state.asReadonly();
  ref: DynamicDialogRef | undefined;
  showNotDeletableDialog = false;

  constructor(
    private titleService: Title,
    private vendorService: VendorsService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.vendorService.resetApiParams();
  }

  viewVendor(vendor: Vendor) {
    this.vendorService.set('selectedVendor', vendor);
  }

  newVendor() {
    this.vendorService.set('selectedVendor', undefined);
    this.vendorService.set('formEditMode', false);
    this.openForm();
  }

  editVendor(vendor: Vendor) {
    this.vendorService.set('selectedVendor', vendor);
    this.vendorService.set('formEditMode', true);
    this.openForm();
  }

  deleteVendor(vendor: Vendor) {
    this.vendorService.set('selectedVendor', undefined);

    if (vendor.assetsCount! > 0 || vendor.staffsCount! > 0 || vendor.locationsCount! > 0) {
      // Not allow to delete vendor if it has assets, staffs or locations
      this.showNotDeletableDialog = true;
    } else {
      this.confirmationService.confirm({
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to delete ?',
        accept: () => {
          vendor.id && this.vendorService.delete(vendor.id).subscribe();
        },
      });
    }
  }

  private openForm() {
    this.ref = this.dialogService.open(VendorFormComponent, {
      header: this.vendorServiceState().formEditMode ? 'Edit Vendor' : 'New Vendor',
      styleClass: 'p-fluid',
      width: '50vw',
      modal: true,
      draggable: true,
    });
  }
}
