import { Injectable } from '@angular/core';
import { ToastService } from '@core/services/toast.service';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';
import { GetAllAPIParams, SignalsSimpleStoreService } from '@core/services';
import { RolesApiService } from './roles-api.service';
import { Role } from '@core/models/role.model';

interface RolesAPIParams extends GetAllAPIParams {}

export interface RolesServiceState {
  roles: Role[];
  total: number;
  loading: boolean;
  apiParams: RolesAPIParams;
}

@Injectable({
  providedIn: 'root',
})
export class RolesService extends SignalsSimpleStoreService<RolesServiceState> {
  constructor(
    private toastService: ToastService,
    private httpClient: HttpClient,
    private rolesApiService: RolesApiService,
  ) {
    super();
  }

  fetchRoles() {
    this.set('loading', true);
    return this.rolesApiService.getAll().pipe(
      tap((res: any) => {
        this.set('roles', res.data);
        this.set('total', res.meta?.total);
      }),
    );
  }
}
