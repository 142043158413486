import { AfterViewInit, Component, Signal } from '@angular/core';
import { UsersService, UsersServiceState } from '@modules/users/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Subject, tap } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

@UntilDestroy()
@Component({
  selector: 'app-user-filters',
  templateUrl: './user-filters.component.html',
  styleUrls: ['./user-filters.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class UserFiltersComponent implements AfterViewInit {
  // The component subscribe and fetch data from service state
  readonly usersServiceState: Signal<UsersServiceState> = this.usersService.state.asReadonly();
  filter: any = this.usersServiceState().apiParams.filter;

  $filterEmailChanged: Subject<string> = new Subject();
  $filterReset: Subject<boolean> = new Subject();

  constructor(private usersService: UsersService) {}

  ngAfterViewInit() {
    this.onFilterReset();
    merge(
      this.$filterEmailChanged.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap((value: string) => {
          this.usersService.apiParams.filter.email = value.trim();
        }),
      ),
      this.$filterReset,
    )
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.usersService.apiParams.page.number = 1;
          this.usersService.resetPaginatorState();
        }),
        switchMap(() => this.usersService.fetchUsers()),
      )
      .subscribe();
  }

  onEmailChanged(ev: any) {
    this.$filterEmailChanged.next(ev.target.value);
  }

  onFilterReset() {
    this.filter = {}; // Force reset filter
    this.usersService.resetApiParams();
    this.$filterReset.next(true);
  }
}
