import { Component, EventEmitter, OnInit, Output, Signal } from '@angular/core';
import { User } from '@core/models';
import { UsersService, UsersServiceState } from '@modules/users/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TablePageEvent } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { ToastService } from '@core/services';

@UntilDestroy()
@Component({
  selector: 'app-user-datatable',
  templateUrl: './user-datatable.component.html',
  styleUrls: ['./user-datatable.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class UserDatatableComponent implements OnInit {
  @Output() onNewUser: EventEmitter<any> = new EventEmitter<User>();
  @Output() onViewUser: EventEmitter<any> = new EventEmitter<User>();
  @Output() onEditUser: EventEmitter<any> = new EventEmitter<User>();
  @Output() onDeleteUser: EventEmitter<any> = new EventEmitter<User>();
  @Output() onChangePassword: EventEmitter<any> = new EventEmitter<User>();

  // The component subscribe and fetch data from service state
  readonly usersServiceState: Signal<UsersServiceState> = this.usersService.state.asReadonly();

  skeletonHeaders = new Array(7).fill('_');
  skeletonUsers = new Array(this.usersServiceState().paginatorState.rows).fill(1);

  constructor(
    public usersService: UsersService,
    protected toastService: ToastService,
  ) {}

  ngOnInit() {
    this.usersService.resetPaginatorState();
    this.usersService.fetchUsers().pipe(untilDestroyed(this)).subscribe();
  }

  newUser() {
    this.onNewUser.emit();
  }

  changePassword(user: User) {
    this.onChangePassword.emit(user);
  }

  editUser(user: User) {
    this.onEditUser.emit(user);
  }

  deleteUser(user: User) {
    this.onDeleteUser.emit(user);
  }

  onPageChange(event: TablePageEvent | any) {
    const isPerPageChanged = event.rows != this.usersServiceState().paginatorState.rows;

    this.usersService.set('paginatorState', {
      first: isPerPageChanged ? 0 : event.first,
      rows: event.rows,
    });

    this.usersService.apiParams.page = {
      number: isPerPageChanged ? 1 : event.page + 1,
      size: event.rows,
    };

    this.usersService.fetchUsers().pipe(untilDestroyed(this)).subscribe();
  }
}
