<div class="mb-4">
  <span class="font-bold text-lg">Most recent scan</span>
</div>
<p-table
  [tableStyle]="{ 'min-width': '75rem' }"
  [value]="scanHistoryServiceState().loading ? skeletonHeaders : scanHistoryServiceState().scanHistories"
  styleClass="p-datatable-gridlines">
  <ng-template pTemplate="header">
    <tr>
      <th>No</th>
      <th>Vendor</th>
      <th>Location</th>
      <th>Scanned By</th>
      <th>Scanned Time</th>
    </tr>
  </ng-template><!-- /Headers -->

  <!-- Body -->
  <ng-template let-history let-i="rowIndex" pTemplate="body">
    <!-- Skeleton vendors & loading -->
    <tr *ngIf="scanHistoryServiceState().loading">
      <ng-container *ngFor="let _ of skeletonHeaders">
        <td class="pl-3">
          <p-skeleton height="2rem"></p-skeleton>
        </td>
      </ng-container>
    </tr><!-- /End loading -->

    <tr *ngIf="!scanHistoryServiceState().loading">
      <td>{{ i + 1 }}</td>
      <td [title]="history.vendorId">{{ history.vendor?.name }}</td>
      <td [title]="history.locationId">{{ history.location?.name }}</td>
      <td [title]="history.staffId">{{ history.staff?.name }}</td>
      <td>{{ history.checkedTime  | date: 'MM/dd/YYYY, hh:mm:ss a' }}</td>
    </tr>
  </ng-template><!-- /Body -->
</p-table>
