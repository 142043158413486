<p-table
  (onSort)="onSort($event)"
  [lazy]="true"
  [tableStyle]="{ 'min-width': '30rem' }"
  [value]="scanHistoryServiceState().loading ? skeletonHeaders : scanHistoryServiceState().scanHistories"
  styleClass="p-datatable-gridlines">
  <ng-template pTemplate="header">
    <tr>
      <th style="width:20%">Tag Id</th>
      <th class="whitespace-nowrap">Asset Type</th>
      <th style="width:20%">Vendor</th>
      <th style="width:20%">Location</th>
      <th class="whitespace-nowrap">Info 1</th>
      <th class="whitespace-nowrap">Info 2</th>
      <th class="whitespace-nowrap">Info 3</th>
      <th style="width:20%">Scanned By</th>
      <th class="whitespace-nowrap" pSortableColumn="checkedTime" style="width:20%">Scanned Time
        <p-sortIcon field="checkedTime"></p-sortIcon>
      </th>
    </tr>
  </ng-template><!-- /Headers -->

  <!-- Body -->
  <ng-template let-history pTemplate="body">
    <!-- Skeleton vendors & loading -->
    <tr *ngIf="scanHistoryServiceState().loading">
      <ng-container *ngFor="let _ of skeletonHeaders">
        <td class="pl-3">
          <p-skeleton height="2rem"></p-skeleton>
        </td>
      </ng-container>
    </tr><!-- /End loading -->

    <tr *ngIf="!scanHistoryServiceState().loading">
      <td class="whitespace-nowrap">
        <i (cbOnSuccess)="toastService.onCopySuccess()"
           [cbContent]="history.tagId"
           [pTooltip]="history.id"
           class="pi pi-copy hover:cursor-pointer" ngxClipboard
           tooltipPosition="right"></i>
        {{ history.tagId }}
      </td>
      <td [title]="history.assetTypeId">{{ history.asset?.assetType?.name }}</td>
      <td [title]="history.vendorId">{{ history.vendor?.name }}</td>
      <td [title]="history.locationId">{{ history.location?.name }}</td>
      <td class="whitespace-nowrap">{{ history.asset?.info1 }}</td>
      <td class="whitespace-nowrap">{{ history.asset?.info2 }}</td>
      <td class="whitespace-nowrap">{{ history.asset?.info3 }}</td>
      <td [title]="history.staffId">{{ history.staff?.name }}</td>
      <td>{{ history.checkedTime  | date: 'MM/dd/YYYY, hh:mm:ss a' }}</td>
    </tr>
  </ng-template><!-- /Body -->
</p-table>

<div class="flex vendors-center justify-end mt-3">
  <p-paginator (onPageChange)="onPageChange($event)"
               [first]="scanHistoryServiceState().paginatorState.first"
               [pageLinkSize]=4
               [rowsPerPageOptions]="[10, 20, 50,100]"
               [rows]="scanHistoryServiceState().paginatorState.rows"
               [showCurrentPageReport]="true"
               [showFirstLastIcon]="true"
               [showJumpToPageInput]="true"
               [showPageLinks]="true"
               [totalRecords]="scanHistoryServiceState().total"
               currentPageReportTemplate="{first} - {last} of {totalRecords}">
  </p-paginator>
</div>
