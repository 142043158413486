<div class="flex flex-col space-y-2">
  <p-divider type="solid"></p-divider>
  <div class="flex flex-row w-full space-x-4 ">
    <app-tag-info class="flex-grow"></app-tag-info>
    <p-divider layout="vertical"></p-divider>
    <app-vendor-info class="flex-none w-1/3"></app-vendor-info>
  </div>

  <p-divider type="solid"></p-divider>
  <div class="flex-grow ml-0">
    <app-scan-history></app-scan-history>
  </div>
</div>
