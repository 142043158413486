<app-overlay>
  <p-avatar
    [style]="{ 'background-color': '#8e9c9f', color: '#ffffff'} "
    iconContent
    styleClass=" hover:cursor-pointer"
  >
    <span class="text-base font-bold mx-2 select-none">{{ getUserName() | uppercase }}</span>
  </p-avatar>
  <div class="flex flex-col w-[300px] space-y-3 bg-white" overlayContent>
    <div class="flex flex-row items-center space-x-7">
      <p-avatar [style]="{ 'background-color': '#8e9c9f', color: '#ffffff'}" size="xlarge">
        <span class="text-3xl font-bold mx-2 select-none">{{ getUserName() | uppercase }}</span>
      </p-avatar>
      <div class="flex flex-col">
        <span class="text-3xl font-bold select-none">{{ authService.getSession().name }}</span>
        <span class="select-none">{{ authService.getSession().email }}</span>
      </div>
    </div>
    <button (click)="logout()" class="p-button p-button-outlined" label="Log Out" pButton></button>
  </div>
</app-overlay>
