import { Component, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Title } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { VendorsService } from '@modules/vendors/services';
import { Staff } from '@core/models';
import { StaffsService, StaffsServiceState } from '@modules/staffs/services';
import {
  StaffDatatableComponent,
  StaffFiltersComponent,
  StaffsFormComponent,
  StaffsFormPasswordComponent,
} from '@modules/staffs/components';
import { LocationsService } from '@modules/locations/services';

@UntilDestroy()
@Component({
  selector: 'app-staffs',
  standalone: true,
  imports: [CommonModule, SharedModule, StaffDatatableComponent, StaffFiltersComponent],
  templateUrl: './staffs.component.html',
  styleUrls: ['./staffs.component.scss'],
})
export class StaffsComponent implements OnInit {
  readonly title = 'Staff Management';
  // The component subscribe and fetch data from service state
  readonly staffServiceState: Signal<StaffsServiceState> = this.staffService.state.asReadonly();
  showNotDeletableDialog = false;
  ref: DynamicDialogRef | undefined;

  constructor(
    private titleService: Title,
    private staffService: StaffsService,
    private confirmationService: ConfirmationService,
    private vendorService: VendorsService,
    private locationsService: LocationsService,
    public dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.staffService.resetApiParams();

    // Fetch all vendors
    this.vendorService.apiParams.page = {
      number: 0,
      size: 'all',
    };
    this.vendorService.getAll().pipe(untilDestroyed(this)).subscribe();

    // Fetch all locations
    this.locationsService.apiParams.page = {
      number: 0,
      size: 'all',
    };
    this.locationsService.getAll().pipe(untilDestroyed(this)).subscribe();
  }

  viewStaff(staff: Staff) {
    this.staffService.set('selectedStaff', staff);
  }

  newStaff() {
    this.staffService.set('selectedStaff', undefined);
    this.staffService.set('formEditMode', false);
    this.openForm();
  }

  editStaff(staff: Staff) {
    this.staffService.set('selectedStaff', staff);
    this.staffService.set('formEditMode', true);
    this.openForm();
  }

  changePassword(staff: Staff) {
    this.staffService.set('selectedStaff', staff);
    this.ref = this.dialogService.open(StaffsFormPasswordComponent, {
      header: 'Change password',
      styleClass: 'p-fluid',
      width: '50vw',
      modal: true,
      draggable: true,
    });
  }

  deleteStaff(staff: Staff) {
    if (staff?.scanHistoryCount > 0) {
      this.showNotDeletableDialog = true;
    } else {
      this.staffService.set('selectedStaff', undefined);
      this.confirmationService.confirm({
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to delete ?',
        accept: () => {
          staff.id && this.staffService.delete(staff.id).subscribe();
        },
      });
    }
  }

  private openForm() {
    this.ref = this.dialogService.open(StaffsFormComponent, {
      header: this.staffServiceState().formEditMode ? 'Edit Staff' : 'New Staff',
      styleClass: 'p-fluid',
      width: '50vw',
      modal: true,
      draggable: true,
    });
  }
}
