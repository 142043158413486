import { AfterViewInit, Component, Signal } from '@angular/core';
import { AssetTypeService, AssetTypesServiceState, ItemsService, ItemsServiceState } from '@modules/items/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Subject, tap } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { VendorsService, VendorsServiceState } from '@modules/vendors/services';
import { LocationsService, LocationsServiceState } from '@modules/locations/services';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { identity, keys, pickBy } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'app-item-filters',
  templateUrl: './item-filters.component.html',
  styleUrls: ['./item-filters.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class ItemFiltersComponent implements AfterViewInit {
  // The component subscribe and fetch data from service state
  readonly itemsServiceState: Signal<ItemsServiceState> = this.itemsService.state.asReadonly();
  readonly vendorsServiceState: Signal<VendorsServiceState> = this.vendorsService.state.asReadonly();
  readonly locationsServiceState: Signal<LocationsServiceState> = this.locationsService.state.asReadonly();
  readonly assetTypesServiceState: Signal<AssetTypesServiceState> = this.assetTypeService.state.asReadonly();
  filter: any = this.itemsServiceState().apiParams.filter;

  $filterTagIdChanged: Subject<string> = new Subject();
  $filterInfo1Changed: Subject<string> = new Subject();
  $filterReset: Subject<boolean> = new Subject();
  $filterChanged: Subject<any> = new Subject();

  constructor(
    private itemsService: ItemsService,
    private vendorsService: VendorsService,
    private locationsService: LocationsService,
    private assetTypeService: AssetTypeService,
  ) {}

  get resetButtonStatus(): boolean {
    return keys(pickBy(this.itemsService.apiParams.filter, identity)).length > 0;
  }

  ngAfterViewInit() {
    this.onFilterReset();
    merge(
      this.$filterTagIdChanged.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap((val: string) => {
          this.itemsService.apiParams.filter.tagId = val;
        }),
      ),
      this.$filterInfo1Changed.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap((val: string) => {
          this.itemsService.apiParams.filter.info1 = val;
        }),
      ),
      this.$filterReset,
      this.$filterChanged,
    )
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.itemsService.apiParams.page.number = 1;
          this.itemsService.resetPaginatorState();
        }),
        switchMap(() => this.itemsService.fetchItems()),
      )
      .subscribe();
  }

  onTagIdChanged(ev: any) {
    this.$filterTagIdChanged.next(ev.target.value);
  }

  onInfo1Changed(ev: any) {
    this.$filterInfo1Changed.next(ev.target.value);
  }

  changeVendor(ev: any) {
    // Check if ev is undefined or null
    if (!ev) {
      return;
    }

    this.itemsService.apiParams.filter.vendorId = ev.value ? ev.value.id : undefined;
    this.$filterChanged.next(true);
  }

  changeLocation(ev: any) {
    this.itemsService.apiParams.filter.locationId = ev.value ? ev.value.id : undefined;
    this.$filterChanged.next(true);
  }

  changeType(ev: any) {
    this.itemsService.apiParams.filter.assetTypeId = ev.value ? ev.value.id : undefined;
    this.$filterChanged.next(true);
  }

  onFilterReset() {
    this.filter = {}; // Force reset filter
    this.itemsService.resetApiParams();
    this.$filterReset.next(true);
  }
}
