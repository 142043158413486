import { Injectable } from '@angular/core';
import { AbstractApiService } from '@core/services';
import { AssetType } from '@core/models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetTypeApiService extends AbstractApiService<AssetType> {
  constructor() {
    super(`${environment.backend?.url}/asset-types`);
  }
}
