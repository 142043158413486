import { Component, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationsService, LocationsServiceState } from '@modules/locations/services';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Title } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { Location } from '@core/models';
import { SharedModule } from '@shared/shared.module';
import {
  LocationDatatableComponent,
  LocationFilterComponent,
  LocationFormComponent,
} from '@modules/locations/components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VendorsService } from '@modules/vendors/services';

@UntilDestroy()
@Component({
  selector: 'app-locations',
  standalone: true,
  imports: [CommonModule, SharedModule, LocationDatatableComponent, LocationFilterComponent, LocationFormComponent],
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  readonly title = 'Location Management';
  // The component subscribe and fetch data from service state
  readonly locationServiceState: Signal<LocationsServiceState> = this.locationService.state.asReadonly();
  ref: DynamicDialogRef | undefined;
  showNotDeletableDialog = false;

  constructor(
    private titleService: Title,
    private locationService: LocationsService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private vendorService: VendorsService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.locationService.resetApiParams();
    this.vendorService.apiParams.page = {
      number: 0,
      size: 'all',
    };
    this.vendorService.getAll().pipe(untilDestroyed(this)).subscribe();
  }

  viewLocation(location: Location) {
    this.locationService.set('selectedLocation', location);
  }

  newLocation() {
    this.locationService.set('selectedLocation', undefined);
    this.locationService.set('formEditMode', false);
    this.openForm();
  }

  editLocation(location: Location) {
    this.locationService.set('selectedLocation', location);
    this.locationService.set('formEditMode', true);
    this.openForm();
  }

  deleteLocation(location: Location) {
    this.locationService.set('selectedLocation', undefined);

    if (location.assetsCount! > 0) {
      // Not allow to delete vendor if it has assets, staffs or locations
      this.showNotDeletableDialog = true;
    } else {
      this.confirmationService.confirm({
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to delete ?',
        accept: () => {
          location.id && this.locationService.delete(location.id).subscribe();
        },
      });
    }
  }

  private openForm() {
    this.ref = this.dialogService.open(LocationFormComponent, {
      header: this.locationServiceState().formEditMode ? 'Edit Location' : 'New Location',
      styleClass: 'p-fluid',
      width: '50vw',
      modal: true,
      draggable: true,
    });
  }
}
