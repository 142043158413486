import { Injectable } from '@angular/core';
import { User } from '@core/models';
import { AbstractApiService } from '@core/services';
import { environment } from '@env/environment';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService extends AbstractApiService<User> {
  constructor() {
    // Set your API endpoint here
    super(`${environment.backend?.url}/users`);
  }

  // Additional methods specific to UserApiService if needed
  // For example, you may want to add methods for specific business logic related to users.
  changePassword(id: string, password: { password: string; confirmPassword: string }) {
    return this.httpClient.patch(`${this.baseUrl}/${id}/password`, password).pipe(
      tap(() => {
        this.toastService.onGetSuccess('Updated user password successfully!');
      }),
    );
  }
}
