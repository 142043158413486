import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { catchError, Observable, of, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AvailableValidator {
  constructor(private httpClient: HttpClient) {}

  validate(apiToCheck: string, fieldToCheck: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }

      return timer(1000).pipe(
        switchMap(() => {
          let params = {
            [`filter[${fieldToCheck}]`]: control.value,
            'page[size]': '1',
            'page[number]': '1',
          };

          return this.httpClient.get(`${environment.backend?.url}/${apiToCheck}`, { params }).pipe(
            map((res: any) => {
              if (res.data.length > 0) {
                return { duplicated: true };
              }
              return null;
            }),
            catchError(() => of(null)),
          );
        }),
      );
    };
  }
}
