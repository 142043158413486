import { Component, EventEmitter, OnInit, Output, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Staff } from '@core/models';
import { StaffsService, StaffsServiceState } from '@modules/staffs/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TablePageEvent } from 'primeng/table';
import { SharedModule } from '@shared/shared.module';
import { ToastService } from '@core/services';

@UntilDestroy()
@Component({
  selector: 'app-staff-datatable',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './staff-datatable.component.html',
  styleUrls: ['./staff-datatable.component.scss'],
})
export class StaffDatatableComponent implements OnInit {
  @Output() onNewStaff: EventEmitter<any> = new EventEmitter<Staff>();
  @Output() onViewStaff: EventEmitter<any> = new EventEmitter<Staff>();
  @Output() onEditStaff: EventEmitter<any> = new EventEmitter<Staff>();
  @Output() onDeleteStaff: EventEmitter<any> = new EventEmitter<Staff>();
  @Output() onChangePasswordStaff: EventEmitter<any> = new EventEmitter<Staff>();
  relationSorts: string[] = ['vendor', 'location'];

  // The component subscribe and fetch data from service state
  readonly staffsServiceState: Signal<StaffsServiceState> = this.staffsService.state.asReadonly();

  skeletonHeaders = new Array(7).fill('_');
  skeletonStaffs = new Array(this.staffsServiceState().paginatorState.rows).fill(1);

  constructor(
    public staffsService: StaffsService,
    protected toastService: ToastService,
  ) {}

  ngOnInit() {
    this.staffsService.resetPaginatorState();
    this.staffsService.getAll().pipe(untilDestroyed(this)).subscribe();
  }

  newStaff() {
    this.onNewStaff.emit();
  }

  editStaff(staff: Staff) {
    this.onEditStaff.emit(staff);
  }

  deleteStaff(staff: Staff) {
    this.onDeleteStaff.emit(staff);
  }

  changePassword(staff: Staff) {
    this.onChangePasswordStaff.emit(staff);
  }

  onSort(event: any) {
    if (event) {
      if (this.relationSorts.includes(event.field)) {
        this.staffsService.apiParams.sort = {
          field: event.field,
          order: JSON.stringify({ name: event.order === 1 ? 'asc' : 'desc' }),
        };
      } else {
        this.staffsService.apiParams.sort = {
          field: event.field,
          order: event.order === 1 ? 'asc' : 'desc',
        };
      }
      this.staffsService.getAll().pipe(untilDestroyed(this)).subscribe();
    }
  }

  onPageChange(event: TablePageEvent | any) {
    const isPerPageChanged = event.rows != this.staffsServiceState().paginatorState.rows;

    this.staffsService.set('paginatorState', {
      first: isPerPageChanged ? 0 : event.first,
      rows: event.rows,
    });

    this.staffsService.apiParams.page = {
      number: isPerPageChanged ? 1 : event.page + 1,
      size: event.rows,
    };

    this.staffsService.getAll().pipe(untilDestroyed(this)).subscribe();
  }
}
