<p-table
  (onSort)="onSort($event)"
  [lazy]="true"
  [tableStyle]="{ 'min-width': '75rem' }"
  [value]="vendorsServiceState().loading ? skeletonVendors : vendorsServiceState().vendors"
  styleClass="p-datatable-gridlines">
  <!-- Headers -->
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="name" style="width:20%">Name
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th class="whitespace-pre-line">Address</th>
      <th>Contact Person</th>
      <th>Phone</th>
      <th>No.Assets</th>
      <th>Created At</th>
      <th>Updated At</th>
      <th>
        <div class="flex w-full justify-end">
          <button (click)="newVendor()"
                  class="p-button p-ripple primary-500"
                  icon="pi pi-plus"
                  label="New"
                  pButton pRipple>
          </button>
        </div>
      </th>
    </tr>
  </ng-template><!-- /Headers -->

  <!-- Body -->
  <ng-template let-vendor pTemplate="body">
    <!-- Skeleton vendors & loading -->
    <tr *ngIf="vendorsServiceState().loading">
      <ng-container *ngFor="let _ of skeletonHeaders">
        <td class="pl-3">
          <p-skeleton height="2rem"></p-skeleton>
        </td>
      </ng-container>
    </tr><!-- /End loading -->

    <tr *ngIf="!vendorsServiceState().loading">
      <td [title]=vendor.id><i (cbOnSuccess)="toastService.onCopySuccess()" [cbContent]="vendor.id"
                               [pTooltip]="vendor.id"
                               class="pi pi-copy hover:cursor-pointer"
                               ngxClipboard
                               tooltipPosition="right"></i> {{ vendor.name }}
      </td>
      <td>{{ vendor.address }}</td>
      <td>{{ vendor.pic }}</td>
      <td class="whitespace-nowrap">{{ vendor.phone }}</td>
      <td style="text-align: center">
        <p-tag [style]="{'width': '4rem','font-weight': 'bold'}" [value]="vendor.assetsCount"></p-tag>
      </td>
      <td style="width: 230px;">{{ vendor.createdAt | date: 'MM/dd/YYYY, hh:mm:ss a' }}</td>
      <td style="width: 230px;">{{ vendor.updatedAt | date: 'MM/dd/YYYY, hh:mm:ss a' }}</td>
      <td>
        <div class="flex w-full justify-end space-x-4 px-4">
          <button (click)="editVendor(vendor)"
                  class="p-button-rounded p-button-secondary p-3 p-button-outlined mr-1"
                  icon="pi pi-pencil"
                  pButton
                  pTooltip="Edit"
                  tooltipPosition="bottom">
          </button>
          <button (click)="deleteVendor(vendor)"
                  class="p-button-rounded p-button-danger p-3 p-button-outlined"
                  icon="pi pi-trash"
                  pButton
                  pTooltip="Delete"
                  tooltipPosition="bottom">
          </button>
        </div>
      </td>
    </tr>
  </ng-template><!-- /Body -->
</p-table>

<div class="flex vendors-center justify-end mt-3">
  <p-paginator (onPageChange)="onPageChange($event)"
               [first]="vendorsServiceState().paginatorState.first"
               [pageLinkSize]=4
               [rowsPerPageOptions]="[10, 20, 50]"
               [showCurrentPageReport]="true"
               [showFirstLastIcon]="true"
               [showJumpToPageInput]="true"
               [showPageLinks]="true"
               [totalRecords]="vendorsServiceState().total"
               currentPageReportTemplate="{first} - {last} of {totalRecords}">
  </p-paginator>
</div>
