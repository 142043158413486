import { environment } from 'src/environments/environment';

export const SETTINGS_ENDPOINT = 'settings';

export const DATA_ENDPOINT = 'data';
export const STATUS_ENDPOINT = 'status';
export const DATE_ENDPOINT = 'dates';

export const API_KEY = environment.backend?.apiKey;

export const AWS_ENDPOINT_PREFIX = environment.backend?.url;

export const ALERT_RULES_ENDPOINT = 'AlertRules';
export const AWS_ENDPOINTS_SNS = {
  SUBSCRIBE_EMAIL_TO_TOPIC: AWS_ENDPOINT_PREFIX + 'subscribe-to-topic',
  LIST_OF_SUBSCRIPTIONS: AWS_ENDPOINT_PREFIX + 'list-subscribers-from-topic',
  CREATE_TOPIC: AWS_ENDPOINT_PREFIX + 'create-topic',
  SET_TOPIC_ATTRIBUTES: AWS_ENDPOINT_PREFIX + 'set-topic-attributes',

  UNSUBSCRIBE_EMAIL_FROM_TOPIC: AWS_ENDPOINT_PREFIX + 'unsubscribe-from-topic',
  DELTE_TOPIC: AWS_ENDPOINT_PREFIX + 'delete-topic',
  LIST_TOPICS: AWS_ENDPOINT_PREFIX + 'list-topics',
  GET_TOPIC_ATTRIBUTES: AWS_ENDPOINT_PREFIX + 'get-topic-attributes',
  MESSAGE_SUBSCRIBERS: AWS_ENDPOINT_PREFIX + 'message-subscribers',
};

export const ROLE_ENDPOINT = 'Roles';

export const ROLE_ENDPOINTS = {
  GET_RULES: 'GetRoleRules',
  // SAVE_USER: 'CreateSaveUser',
  // DELETE_USER: 'DeleteUser',
  // GET_USERS: 'GetUsers',
};
