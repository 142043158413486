import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { AppInitializerService } from './app-initializer.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { defaultIfEmpty, filter, map, switchMap } from 'rxjs/operators';

export interface DscSubscription {
  accountId: string;
  articleNo: string;
  billingStartDate: string;
  billingSubscriptionId: string;
  chargedThroughDate: string;
  created: number;
  id: string;
  planId: string;
  productId: string;
  serviceId: string;
  status: string;
  subscriptionId: string;
  timeZone: string;
}

export interface License {
  subscription: DscSubscription | null;
  state: 'rejected' | 'success';
}

@Injectable()
export class LicensingService {
  // getHeader() {
  //   return {
  //     'x-api-key': environment.assetHub.apiKey,
  //     'x-bearer': 'Bearer ' + this.bearer,
  //     'x-user': this.userId,
  //   };
  // }

  // getBearer() {
  //   return 'Bearer ' + this.bearer;
  // }

  // getUserId() {
  //   return this.userId;
  // }
  private license$ = new BehaviorSubject<License | undefined>(undefined);

  constructor(
    private httpClient: HttpClient,
    init: AppInitializerService,
    private oauth: OAuthService,
  ) {
    init.loggedIn$
      .pipe(
        filter((loggedIn) => loggedIn),
        switchMap(() => from(oauth.loadUserProfile())),
        map((user: any) => user.info.sub),
        map(
          (sub) =>
            `${environment.digitalServiceCatalog.subscriptionApiUrl}users/${sub}/subscriptions?service_id=${environment.digitalServiceCatalog.serviceIds?.basic}`,
        ),
        switchMap((url) => this.httpClient.get<{ Items: Array<DscSubscription> }>(url)),
        filter((response) => !!response?.Items?.length),
        map((response) => ({ subscription: response.Items[0], state: 'success' }) as License),
        defaultIfEmpty({ subscription: null, state: 'rejected' } as License),
      )
      .subscribe({
        next: (l) => this.license$.next(l),
        error: () => this.license$.next({ subscription: null, state: 'rejected' } as License),
      });
  }

  get currentLicense$(): Observable<License | undefined> {
    return this.license$;
  }
}
