import { Component, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { SharedModule } from '@shared/shared.module';
import { ScanHistoryDatatableComponent } from './components/scan-history-datatable/scan-history-datatable.component';
import { ScanHistoryFiltersComponent } from './components/scan-history-filters/scan-history-filters.component';
import { VendorsService } from '@modules/vendors/services';
import { ScanHistoriesService, ScanHistoryServiceState } from '@modules/scan-histories/services';
import { AssetTypeService } from '@modules/items/services';
import { LocationsService } from '@modules/locations/services';
import { StaffsService } from '@modules/staffs/services';
import { CSVHandler } from '@shared/services/csv-handler.service';
import { ScanHistory } from '@core/models';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { GetAllAPIParams } from '@core/services';

@UntilDestroy()
@Component({
  selector: 'app-scan-histories',
  standalone: true,
  imports: [CommonModule, SharedModule, ScanHistoryFiltersComponent, ScanHistoryDatatableComponent, LoadingComponent],
  templateUrl: './scan-histories.component.html',
  styleUrls: ['./scan-histories.component.scss'],
})
export class ScanHistoriesComponent implements OnInit {
  readonly title = 'Scan History';
  readonly scanHistoryServiceState: Signal<ScanHistoryServiceState> = this.scanHistoryService.state.asReadonly();
  readonly csvHandlerState = this.csvHandler.getStates.asReadonly();

  constructor(
    private readonly scanHistoryService: ScanHistoriesService,
    private readonly vendorsService: VendorsService,
    private readonly locationsService: LocationsService,
    private readonly staffsService: StaffsService,
    private readonly assetTypeService: AssetTypeService,
    private readonly csvHandler: CSVHandler<ScanHistory>,
  ) {}

  ngOnInit() {
    this.scanHistoryService.resetApiParams();

    // Get all vendors
    this.vendorsService.apiParams.page = {
      number: 0,
      size: 'all',
    };
    this.vendorsService.getAll().pipe(untilDestroyed(this)).subscribe();

    // Get all locations
    this.locationsService.apiParams.page = {
      number: 0,
      size: 'all',
    };
    this.locationsService.getAll().pipe(untilDestroyed(this)).subscribe();

    // Get all staffs
    this.staffsService.apiParams.page = {
      number: 0,
      size: 'all',
    };
    this.staffsService.getAll().pipe(untilDestroyed(this)).subscribe();

    // Get all asset types
    this.assetTypeService.apiParams.page = {
      number: 0,
      size: 'all',
    };
    this.assetTypeService.fetchAssetTypes().pipe(untilDestroyed(this)).subscribe();

    this.configureCSVHandler();
  }

  exportToDEAM() {
    this.scanHistoryService.exportToDEAM().pipe(untilDestroyed(this)).subscribe();
  }

  downloadCSV() {
    this.csvHandler.setTotalPage = this.scanHistoryServiceState().total;
    const params = { ...this.scanHistoryServiceState().apiParams } as GetAllAPIParams;
    params.filter['assetMustExist'] = true; // Only export scanHistories with asset info

    this.csvHandler
      .generateContent(params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (csvBlob) => {
          if (!csvBlob) return;

          const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.csv`;
          this.csvHandler.downloadFile(csvBlob, fileName);
        },
      });
  }

  private configureCSVHandler() {
    this.csvHandler.setApiUrl = '/scanHistories';
    this.csvHandler.setDelimiter = ';';
    this.csvHandler.setColumns = [
      { title: 'TagId', field: 'tagId' },
      { title: 'Asset Type', value: (data: ScanHistory) => data?.asset?.assetType?.name },
      { title: 'Vendor', value: (data: ScanHistory) => data.vendor?.name },
      { title: 'Location', value: (data: ScanHistory) => data.location?.name },
      { title: 'Info 1', value: (data: ScanHistory) => data?.asset?.info1 },
      { title: 'Info 2', value: (data: ScanHistory) => data?.asset?.info2 },
      { title: 'Info 3', value: (data: ScanHistory) => data?.asset?.info3 },
      { title: 'Scanned By', value: (data: ScanHistory) => data.staff?.name },
      {
        title: 'Scanned Time',
        value: (data: ScanHistory) => dayjs(data.checkedTime).format('MM/DD/YYYY, hh:mm:ss A'),
      },
    ];
  }
}
