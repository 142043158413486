import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class AppInitializerService {
  private isLoggedIn$ = new BehaviorSubject(false);

  constructor(private authService: OAuthService) {}

  get loggedIn$(): Observable<boolean> {
    return this.isLoggedIn$.pipe(distinctUntilChanged());
  }

  // With SICK ID
  // async initialize(): Promise<unknown> {
  //   this.authService.configure(environment.oidc);
  //   this.authService.setupAutomaticSilentRefresh();
  //   const setLoginState = () =>
  //     this.isLoggedIn$.next(this.authService.hasValidIdToken() && this.authService.hasValidAccessToken());
  //
  //   if (!this.authService.hasValidIdToken()) {
  //     return this.authService.loadDiscoveryDocumentAndLogin().then(setLoginState);
  //   } else {
  //     return this.authService.loadDiscoveryDocument().then(setLoginState);
  //   }
  // }

  // Without SICK ID
  async initialize(): Promise<unknown> {
    this.isLoggedIn$.next(true);
    return true;
  }
}
