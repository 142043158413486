<p-table
  (onSort)="onSort($event)"
  [lazy]="true"
  [tableStyle]="{ 'min-width': '75rem' }"
  [value]="staffsServiceState().loading ? skeletonStaffs : staffsServiceState().staffs"
  styleClass="p-datatable-gridlines">
  <!-- Headers -->
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="name" style="width:20%">Name
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="email" style="width:20%">Email
        <p-sortIcon field="email"></p-sortIcon>
      </th>
      <th>No.Scans</th>
      <th>Department</th>
      <th>Phone</th>
      <th>Updated At</th>
      <th>
        <div class="flex w-full justify-end">
          <button (click)="newStaff()"
                  class="p-button p-ripple primary-500"
                  icon="pi pi-plus"
                  label="New"
                  pButton pRipple>
          </button>
        </div>
      </th>
    </tr>
  </ng-template><!-- /Headers -->

  <!-- Body -->
  <ng-template let-staff pTemplate="body">
    <!-- Skeleton staffs & loading -->
    <tr *ngIf="staffsServiceState().loading">
      <ng-container *ngFor="let _ of skeletonHeaders">
        <td>
          <p-skeleton height="2rem"></p-skeleton>
        </td>
      </ng-container>
    </tr><!-- /End loading -->

    <tr *ngIf="!staffsServiceState().loading">
      <td class="whitespace-nowrap">
        <i (cbOnSuccess)="toastService.onCopySuccess()" [cbContent]="staff.id" [pTooltip]="staff.id"
           class="pi pi-copy hover:cursor-pointer text-xl"
           ngxClipboard
           tooltipPosition="right">
        </i>
        {{ staff.name }}
      </td>
      <td class="whitespace-nowrap">{{ staff.email }}</td>
      <td style="text-align: center">
        <p-tag [style]="{'width': '4rem','font-weight': 'bold'}" [value]="staff?.scanHistoryCount||0">
        </p-tag>
      </td>
      <td class="whitespace-nowrap">{{ staff.department }}</td>
      <td class="whitespace-nowrap">{{ staff.phone }}</td>
      <td class="whitespace-nowrap">{{ staff.updatedAt | date: 'MM/dd/YYYY, hh:mm:ss a' }}</td>
      <td>
        <div class="flex w-full justify-end space-x-4 px-4">
          <button (click)="changePassword(staff)" class="p-button-rounded p-button-secondary p-3 p-button-outlined"
                  icon="pi pi-lock"
                  pButton
                  pTooltip="Change password"
                  tooltipPosition="bottom"></button>

          <button (click)="editStaff(staff)"
                  class="p-button-rounded p-button-secondary p-3 p-button-outlined mr-1"
                  icon="pi pi-pencil"
                  pButton
                  pTooltip="Edit"
                  tooltipPosition="bottom">
          </button>
          <button (click)="deleteStaff(staff)"
                  class="p-button-rounded p-button-danger p-3 p-button-outlined"
                  icon="pi pi-trash"
                  pButton
                  pTooltip="Delete"
                  tooltipPosition="bottom">
          </button>

        </div>
      </td>
    </tr>
  </ng-template><!-- /Body -->
</p-table>

<div class="flex staffs-center justify-end mt-3">
  <p-paginator (onPageChange)="onPageChange($event)"
               [first]="staffsServiceState().paginatorState.first"
               [pageLinkSize]=4
               [rowsPerPageOptions]="[10, 20, 50]"
               [rows]="staffsServiceState().paginatorState.rows"
               [showCurrentPageReport]="true"
               [showFirstLastIcon]="true"
               [showJumpToPageInput]="true"
               [showPageLinks]="true"
               [totalRecords]="staffsServiceState().total"
               currentPageReportTemplate="{first} - {last} of {totalRecords}">
  </p-paginator>
</div>
