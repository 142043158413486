<div class="flex flex-col">
  <span class="font-bold text-lg">Vendor information</span>
  <div class="p-4 space-y-3">
    <div class="flex">
      <div class="flex-1 font-semibold">
        Name
      </div>
      <div class="flex-1">
        {{ itemsServiceState().selectedItem?.vendor?.name }}
      </div>
    </div>
    <div class="flex">
      <div class="flex-1 font-semibold">
        Location
      </div>
      <div class="flex-1">
        {{ itemsServiceState().selectedItem?.location?.name }}
      </div>
    </div>
  </div>
</div>
