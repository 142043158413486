import { Component, OnInit, Signal } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AssetTypeService, AssetTypesServiceState, ItemsService, ItemsServiceState } from '@modules/items/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Item } from '@core/models';
import { hasErrorAndTouched } from '@shared/form-controls.util';
import { SharedModule } from '@shared/shared.module';
import { CommonModule } from '@angular/common';
import { LocationsService, LocationsServiceState } from '@modules/locations/services';
import { DialogService } from 'primeng/dynamicdialog';
import { VendorsService, VendorsServiceState } from '@modules/vendors/services';

@UntilDestroy()
@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class ItemFormComponent implements OnInit {
  // The component subscribe and fetch data from service state
  readonly itemsServiceState: Signal<ItemsServiceState> = this.itemsService.state.asReadonly();
  readonly locationsServiceState: Signal<LocationsServiceState> = this.locationService.state.asReadonly();
  readonly vendorServiceState: Signal<VendorsServiceState> = this.vendorService.state.asReadonly();
  readonly assetTypesServiceState: Signal<AssetTypesServiceState> = this.assetTypeService.state.asReadonly();
  readonly isEditMode = this.itemsServiceState().formEditMode;
  readonly selectedItem: Item | undefined = this.itemsServiceState().selectedItem;
  readonly hasErrorAndTouched = hasErrorAndTouched;
  itemForm: UntypedFormGroup = this.fb.group({
    tagId: ['', Validators.required],
    vendorId: [Validators.required],
    locationId: [Validators.required],
    assetTypeId: [Validators.required],
    info1: [''],
    info2: [''],
    info3: [''],
  });

  constructor(
    private fb: FormBuilder,
    private itemsService: ItemsService,
    private locationService: LocationsService,
    public dialogService: DialogService,
    private vendorService: VendorsService,
    private assetTypeService: AssetTypeService,
  ) {}

  get disableSubmitForm(): boolean {
    return this.itemForm.invalid;
  }

  ngOnInit() {
    let formData!: Item;
    if (this.isEditMode) {
      formData = this.selectedItem as Item;
    } else {
      const assetTypeId = this.assetTypesServiceState().types
        ? (this.assetTypesServiceState().types[0].id as string)
        : '';

      const vendorId: string = (this.vendorServiceState().vendors?.[0].id as string) || '';

      const locationId: string = (this.locationsServiceState().locations?.[0].id as string) || '';
      formData = {
        assetTypeId: assetTypeId,
        itemType: '',
        tagId: '',
        vendorId: vendorId,
        info1: '',
        info2: '',
        info3: '',
        locationId: locationId,
      };
    }
    this.itemForm.patchValue(formData as Item);
  }

  onVendorChange(ev: any) {}

  onSubmit() {
    const payload: Item = { ...this.itemForm.value };
    console.log(payload);
    if (this.isEditMode) {
      this.itemsService.editItem(this.selectedItem?.id as string, payload).subscribe();
    } else {
      this.itemsService.newItem(payload).subscribe();
    }
  }
}
