import { AfterViewInit, Component, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorsService, VendorsServiceState } from '@modules/vendors/services';
import { merge, Subject, tap } from 'rxjs';
import { LocationsService, LocationsServiceState } from '@modules/locations/services';
import { StaffsService, StaffsServiceState } from '@modules/staffs/services';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SharedModule } from '@shared/shared.module';
import { ScanHistoriesService, ScanHistoryServiceState } from '@modules/scan-histories/services';
import { AssetTypeService, AssetTypesServiceState } from '@modules/items/services';
import { identity, keys, pickBy } from 'lodash-es';
import dayjs from 'dayjs';

@UntilDestroy()
@Component({
  selector: 'app-scan-history-filters',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './scan-history-filters.component.html',
  styleUrls: ['./scan-history-filters.component.scss'],
})
export class ScanHistoryFiltersComponent implements AfterViewInit {
  readonly scanHistoryServiceState: Signal<ScanHistoryServiceState> = this.scanHistoryService.state.asReadonly();
  readonly vendorsServiceState: Signal<VendorsServiceState> = this.vendorService.state.asReadonly();
  readonly locationsServiceState: Signal<LocationsServiceState> = this.locationService.state.asReadonly();
  readonly staffsServiceState: Signal<StaffsServiceState> = this.staffService.state.asReadonly();
  readonly assetTypesServiceState: Signal<AssetTypesServiceState> = this.assetTypeService.state.asReadonly();

  $filterTagIdChanged: Subject<string> = new Subject();
  $filterInfo1Changed: Subject<string> = new Subject();
  $filterReset: Subject<boolean> = new Subject();
  $filterChanged: Subject<any> = new Subject();

  filter: any = this.scanHistoryServiceState().apiParams.filter;
  period: any;

  constructor(
    private vendorService: VendorsService,
    private locationService: LocationsService,
    private scanHistoryService: ScanHistoriesService,
    private staffService: StaffsService,
    private assetTypeService: AssetTypeService,
  ) {}

  get resetButtonStatus(): boolean {
    return keys(pickBy(this.scanHistoryService.apiParams.filter, identity)).length > 0;
  }

  ngAfterViewInit() {
    this.onFilterReset();
    merge(
      this.$filterTagIdChanged.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap((value: string) => {
          this.scanHistoryService.apiParams.filter.tagId = value.trim();
        }),
      ),
      this.$filterInfo1Changed.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap((value: string) => {
          this.scanHistoryService.apiParams.filter.info1 = value.trim();
        }),
      ),
      this.$filterReset,
      this.$filterChanged,
    )
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.scanHistoryService.apiParams.page.number = 1;
          this.scanHistoryService.resetPaginatorState();
        }),
        switchMap(() => this.scanHistoryService.getAll()),
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onTagIdChanged(ev: any) {
    this.$filterTagIdChanged.next(ev.target.value);
  }

  onInfo1Changed(ev: any) {
    this.$filterInfo1Changed.next(ev.target.value);
  }

  changeVendor(ev: any) {
    // To call data for the table
    this.scanHistoryService.apiParams.filter.vendorId = ev.value ? ev.value.id : undefined;
    this.$filterChanged.next(true);
  }

  changeLocation(ev: any) {
    //To call data for the table
    this.scanHistoryService.apiParams.filter.locationId = ev.value ? ev.value.id : undefined;
    this.$filterChanged.next(true);
  }

  changeStaff(ev: any) {
    this.scanHistoryService.apiParams.filter.staffId = ev.value ? ev.value.id : undefined;
    this.$filterChanged.next(true);
  }

  changeType(ev: any) {
    // console.log(ev.value);
    this.scanHistoryService.apiParams.filter.assetTypeId = ev.value ? ev.value.id : undefined;
    this.$filterChanged.next(true);
  }

  onCalenderSelect() {
    const fromDate = this.period[0];
    const toDate = this.period[1];

    if (fromDate && toDate) {
      this.scanHistoryService.apiParams.filter.fromDate = dayjs(fromDate).format('YYYY-MM-DD');
      this.scanHistoryService.apiParams.filter.toDate = dayjs(toDate).format('YYYY-MM-DD');
      this.$filterChanged.next(true);
    }
  }

  onFilterReset() {
    this.filter = {}; // Force reset filter
    this.scanHistoryService.resetApiParams();
    this.period = undefined;
    this.$filterReset.next(true);
  }
}
