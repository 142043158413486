import { Injectable } from '@angular/core';
import { GetAllAPIParams, SignalsSimpleStoreService } from '@core/services';
import { Staff } from '@core/models';
import { assign, cloneDeep } from 'lodash-es';
import { StaffsApiService } from '@modules/staffs/services';
import { concatMap, delay, tap } from 'rxjs';
import { TablePageEvent } from 'primeng/table';

interface StaffsAPIParams extends GetAllAPIParams {
  filter: {
    email: string | undefined;
    vendorId: string | undefined;
    locationId: string | undefined;
  };
}

export interface StaffsServiceState {
  staffs: Staff[];
  selectedStaff?: Staff;
  total: number;
  loading: boolean;
  formEditMode: boolean;
  apiParams: StaffsAPIParams;
  paginatorState: TablePageEvent;
}

@Injectable({
  providedIn: 'root',
})
export class StaffsService extends SignalsSimpleStoreService<StaffsServiceState> {
  initialApiParams: StaffsAPIParams = assign({}, this.staffApiService.getAllParams, {
    filter: {
      email: undefined,
      vendorId: undefined,
      locationId: undefined,
    },
  });
  initialPaginatorState: TablePageEvent = assign(
    {},
    {
      first: 0,
      rows: 10,
    },
  );

  apiParams!: StaffsAPIParams;
  paginatorState!: TablePageEvent;

  constructor(private staffApiService: StaffsApiService) {
    super();
    this.resetApiParams();
    this.resetPaginatorState();
  }

  resetApiParams() {
    this.apiParams = cloneDeep(this.initialApiParams);
    this.set('apiParams', this.apiParams);
  }

  resetPaginatorState() {
    this.paginatorState = cloneDeep(this.initialPaginatorState);
    this.set('paginatorState', this.paginatorState);
  }

  getAll() {
    this.set('loading', true);
    return this.staffApiService
      .getAll({
        params: this.apiParams,
      })
      .pipe(
        delay(600), // Add some delay to show loading effect
        tap((res: any) => {
          this.set('loading', false);
          this.set('staffs', res.data);
          this.set('total', res.meta?.total);
        }),
      );
  }

  changPassword(id: string, passwords: { password: string; confirmPassword: string }) {
    return this.staffApiService.changePassword(id, passwords).pipe();
  }

  new(payload: Staff) {
    return this.staffApiService.create(payload).pipe(concatMap(() => this.getAll()));
  }

  edit(id: string | number, payload: Staff) {
    return this.staffApiService.update(id, payload).pipe(concatMap(() => this.getAll()));
  }

  delete(id: string | number) {
    return this.staffApiService.delete(id).pipe(concatMap(() => this.getAll()));
  }
}
