<p-card [header]="title">

  <p-toolbar styleClass="mb-4">
    <app-location-filter></app-location-filter>
  </p-toolbar>

  <app-location-datatable (onDeleteLocation)="deleteLocation($event)"
                          (onEditLocation)="editLocation($event)"
                          (onNewLocation)="newLocation()"
                          (onViewLocation)="viewLocation($event)">
  </app-location-datatable>

  <p-dialog
    header="Notice"
    [(visible)]="showNotDeletableDialog"
    [modal]="true"
    [draggable]="false"
    [resizable]="false">
    <p>
      This location has assets or staffs associated with it.<br>
      Please remove them first.
    </p>
    <p-footer>
      <button pButton type="button" label="OK" (click)="showNotDeletableDialog = false"></button>
    </p-footer>
  </p-dialog>
</p-card>
