import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AbstractApiService } from '@core/services';
import { Staff } from '@core/models';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StaffsApiService extends AbstractApiService<Staff> {
  constructor() {
    super(`${environment.backend?.url}/staffs`);
  }

  changePassword(id: string, password: { password: string; confirmPassword: string }) {
    return this.httpClient.patch(`${this.baseUrl}/${id}/password`, password).pipe(
      tap(() => {
        this.toastService.onGetSuccess('Updated password successfully!');
      }),
    );
  }
}
