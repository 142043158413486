import { AfterViewInit, Component, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VendorsService, VendorsServiceState } from '@modules/vendors/services';
import { merge, Subject, tap } from 'rxjs';
import { LocationsService, LocationsServiceState } from '@modules/locations/services';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SharedModule } from '@shared/shared.module';
import { StaffsService, StaffsServiceState } from '@modules/staffs/services';
import { identity, keys, pickBy } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'app-staff-filters',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './staff-filters.component.html',
  styleUrls: ['./staff-filters.component.scss'],
})
export class StaffFiltersComponent implements AfterViewInit {
  // The component subscribe and fetch data from service state
  readonly staffsServiceState: Signal<StaffsServiceState> = this.staffService.state.asReadonly();
  readonly vendorsServiceState: Signal<VendorsServiceState> = this.vendorService.state.asReadonly();
  readonly locationsServiceState: Signal<LocationsServiceState> = this.locationService.state.asReadonly();
  filter: any = this.staffsServiceState().apiParams.filter;

  $filterEmailChanged: Subject<string> = new Subject();
  $filterReset: Subject<boolean> = new Subject();
  $filterChanged: Subject<any> = new Subject();

  constructor(
    private vendorService: VendorsService,
    protected locationService: LocationsService,
    protected staffService: StaffsService,
  ) {}

  get resetButtonStatus(): boolean {
    return keys(pickBy(this.staffService.apiParams.filter, identity)).length > 0;
  }

  ngAfterViewInit() {
    this.onFilterReset();
    merge(
      this.$filterEmailChanged.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap((email: string) => {
          this.staffService.apiParams.filter.email = email;
        }),
      ),
      this.$filterReset,
      this.$filterChanged,
    )
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.staffService.apiParams.page.number = 1;
          this.staffService.resetPaginatorState();
        }),
        switchMap(() => this.staffService.getAll()),
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onEmailChanged(ev: any) {
    this.$filterEmailChanged.next(ev.target.value);
  }

  changeVendor(ev: any) {
    this.staffService.apiParams.filter.vendorId = ev.value ? ev.value.id : undefined;
    this.$filterChanged.next(true);
  }

  changeLocation(ev: any) {
    this.staffService.apiParams.filter.locationId = ev.value ? ev.value.id : undefined;
    this.$filterChanged.next(true);
  }

  onFilterReset() {
    this.filter = {}; // Force reset filter
    this.staffService.resetApiParams();
    this.$filterReset.next(true);
  }
}
