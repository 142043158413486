<app-loading
  *ngIf="csvHandlerState().loading"
  [value]="csvHandlerState().progressNumber"
  [title]="'Downloading ' + '('+ csvHandlerState().progressNumber  + '%' + ')'"
/>
<p-card [header]="title">
  <p-toolbar class="space-between" styleClass="mb-4 gap-10 space-y-2">
    <div class="p-toolbar-group-start">
      <app-scan-history-filters></app-scan-history-filters>
    </div>

    <div class="p-toolbar-group-end  gap-4">
      <button
        (click)="downloadCSV()"
        class="p-button p-ripple primary-500 "
        icon="pi pi-download"
        label="Download CSV"
        pButton
        pRipple
      ></button>
      <button
        (click)="exportToDEAM()"
        class="p-button p-ripple primary-500 "
        icon="pi pi-file-export"
        label="Export to DEAM"
        pButton
        pRipple
      ></button>
    </div>
  </p-toolbar>

  <app-scan-history-datatable></app-scan-history-datatable>
</p-card>
