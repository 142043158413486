import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validates an IP address.
 * @returns A validator function that returns a `ValidationErrors` object if the IP address is invalid, or `null` if it is valid.
 */
export function IpAddressValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const ipAddress = control.value;
    const segments = ipAddress.split('.');
    if (control.value === '') {
      return null;
    }
    // Check if there are 4 segments
    if (segments.length !== 4) {
      return { invalidIp: true };
    }

    // Check each segment
    for (const segment of segments) {
      const segmentValue = parseInt(segment, 10);
      if (segment.toString()[0] === '0' && segment.toString().length > 1) {
        return { invalidIp: true };
      }
      // Check if the segment is not a valid number or is greater than 255
      if (isNaN(segmentValue) || segmentValue > 255) {
        return { invalidIp: true };
      }
    }

    return null;
  };
}
