import { Injectable } from '@angular/core';
import { AbstractApiService } from '@core/services';
import { Vendor } from '@core/models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class VendorsApiService extends AbstractApiService<Vendor> {
  constructor() {
    super(`${environment.backend?.url}/vendors`);
  }
}
