import { Component } from '@angular/core';
import { AppInitializerService } from 'src/app/core/services/app-initializer.service';
import appPackage from '../../../../package.json';

@Component({
  selector: 'application-header',
  templateUrl: './base-header.component.html',
  styleUrls: ['./base-header.component.scss'],
})
export class BaseHeaderComponent {
  // shown in the header of the application
  appName = appPackage.title;
  appVersion = `v${appPackage.version}`;
  loggedIn = false;

  constructor(appInitializer: AppInitializerService) {
    appInitializer.loggedIn$.subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
  }
}
