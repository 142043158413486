<form (ngSubmit)="onSubmit()" [formGroup]="staffForm" autocomplete="off" class="w-full mt-5">
  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="name" id="name" pInputText/>
            <label htmlFor="name">Name *</label>
        </span>
    <p-message *ngIf="hasErrorAndTouched(staffForm.get('name'), 'required')"
               severity="error" text="*Name is required."></p-message>
  </div>
  <div class="w-full mb-8">
        <span class="p-float-label">
            <input autocomplete="off" class="w-full" formControlName="email" id="email" pInputText
                   type="email"/>
            <label htmlFor="email">Email *</label>
        </span>
    <p-message *ngIf="hasErrorAndTouched(staffForm.get('email'), 'required')"
               severity="error" text="*Email is required."></p-message>
    <p-message *ngIf="hasErrorAndTouched(staffForm.get('email'), 'email')"
               severity="error" text="*Invalid Email."></p-message>
    <p-message *ngIf="hasErrorAndTouched(staffForm.get('email'), 'duplicated')"
               severity="error" text="* Not Available"></p-message>
  </div>

  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="phone" id="phone" pInputText/>
            <label htmlFor="phone">Phone</label>
        </span>
  </div>

  <div class="w-full mb-8">
        <span class="p-float-label">
            <input class="w-full" formControlName="department" id="department" pInputText
            />
            <label htmlFor="department">Department</label>
        </span>
  </div>

  <div *ngIf="!isEditMode" class="w-full mb-8">
      <span class="p-float-label">
          <input autocomplete="off" class="w-full"
                 formControlName="password"
                 id="password" pInputText
                 type="password"
          />
          <label htmlFor="password">Password *</label>
      </span>
    <p-message *ngIf="hasErrorAndTouched(staffForm.get('password'), 'required')"
               severity="error" text="*Password is required."></p-message>
    <p-message *ngIf="hasErrorAndTouched(staffForm.get('password'), 'minLength')
                      || hasErrorAndTouched(staffForm.get('password'), 'alphabeticalCharacterRule')
                      || hasErrorAndTouched(staffForm.get('password'), 'digitCharacterRule')
                      || hasErrorAndTouched(staffForm.get('password'), 'lowercaseCharacterRule')
                      || hasErrorAndTouched(staffForm.get('password'), 'uppercaseCharacterRule')
                      || hasErrorAndTouched(staffForm.get('password'), 'specialCharacterRule')"
               severity="error"
               text="Password must contain at least 8 characters including lowercase, uppercase alphabet, number and 1 special characters "></p-message>
  </div>

  <div *ngIf="!isEditMode" class="w-full mb-8">
    <div class="p-float-label">
      <input class="w-full" formControlName="confirmPassword" pInputText type="password"/>
      <label htmlFor="confirmPassword">Confirm Password *</label>
    </div>
    <p-message *ngIf="hasErrorAndTouched(staffForm.get('confirmPassword'), 'required')"
               severity="error" text="This field is required."></p-message>
    <p-message *ngIf="hasErrorAndTouched(staffForm.get('confirmPassword'), 'mismatchedPasswords')"
               severity="error" text="Confirm password must be same as password"></p-message>
  </div>

  <div class="flex flex-row space-x-4">
    <button [disabled]="disableSubmitForm"
            class="p-button p-ripple p-component mt-3"
            label="Save"
            pButton pRipple type="submit"></button>
  </div>
</form>
