import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ACCESS_TOKEN_KEY } from '@core/services';

/**
 * Interceptor for adding JWT token to HTTP requests.
 */
@Injectable({
  providedIn: 'root',
})
export class JWTInterceptor implements HttpInterceptor {
  constructor() {}

  /**
   * Intercepts HTTP requests and adds the JWT token to the request headers.
   * @param request - The HTTP request to be intercepted.
   * @param next - The next HTTP handler in the interceptor chain.
   * @returns An Observable of the HTTP event.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = JSON.parse(sessionStorage.getItem(ACCESS_TOKEN_KEY) || 'null');

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return next.handle(request).pipe(
      catchError((err) => {
        return throwError(() => err);
      }),
    );
  }
}
