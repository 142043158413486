import { NgModule } from '@angular/core';
import { provideRouter, Routes, RouterModule, withComponentInputBinding } from '@angular/router';
import { authGuard } from '@core/guards/auth.guard';
import { LoginComponent } from '@modules/auth/login/login.component';
import { ItemsComponent } from '@modules/items/items.component';
import { ScanHistoriesComponent } from '@modules/scan-histories/scan-histories.component';
import { VendorsComponent } from '@modules/vendors/vendors.component';
import { UsersComponent } from '@modules/users/users.component';
import { StaffsComponent } from '@modules/staffs/staffs.component';
import { LocationsComponent } from '@modules/locations/locations.component';

export const routes: Routes = [
  // Auth
  { path: 'login', component: LoginComponent, data: { animation: 'login' } },
  // Features modules
  {
    path: 'asset-management',
    component: ItemsComponent,
    canActivate: [authGuard],
    data: { animation: 'asset-management' },
  },
  {
    path: 'scan-histories',
    component: ScanHistoriesComponent,
    canActivate: [authGuard],
    data: { animation: 'scan-histories' },
  },
  {
    path: 'vendor-management',
    component: VendorsComponent,
    canActivate: [authGuard],
    data: { animation: 'vendor' },
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [authGuard],
    data: { animation: 'users' },
  },
  {
    path: 'staff-management',
    component: StaffsComponent,
    canActivate: [authGuard],
    data: { animation: 'staff' },
  },
  {
    path: 'location-management',
    component: LocationsComponent,
    canActivate: [authGuard],
    data: { animation: 'locations' },
  },
  // Default (404)
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  providers: [provideRouter(routes, withComponentInputBinding())],
  exports: [RouterModule],
})
export class AppRoutingModule {}
