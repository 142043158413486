<div class="flex flex-row w-full h-screen relative">
  <!------------- Cover image--------------->
  <div class="hidden  sm:flex flex-grow h-full w-full ">
    <img class="w-full h-full object-cover" src="/assets/images/bg-login.jpg">
  </div>

  <!------------- Form details------------->
  <div
    class="flex flex-col w-full h-full items-center justify-center p-4 sm:w-[500px]  lg:w-[600px] bg-white relative animate-delay-500 animate-slide-in-right">
    <div class=" flex flex-row w-full items-center justify-center mb-3 space-x-3 px-10">
      <div class=" flex flex-grow h-[1px] bg-neutral-400"></div>
      <img alt="Logo" class="h-12" src="/assets/images/dyson-logo.svg">
      <div class=" flex flex-grow h-[1px] bg-neutral-400"></div>
    </div>
    <span [title]="appVersion" class="text-neutral-400 text-xl font-bold">{{ appName }}</span>

    <div class="w-full p-10 md:p-6">
      <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="flex flex-col">
        <div class="w-full mb-8">
          <div class="p-float-label">
            <input class="w-full" formControlName="email" id="email"
                   pInputText placeholder="Email"/>
            <label htmlFor="email">Email</label>
          </div>
          <p-message *ngIf="hasErrorAndTouched(loginForm.get('email'), 'required')"
                     severity="error" text="This field is required."></p-message>
        </div>

        <div class="w-full mb-8">
          <div class="p-float-label">
            <input class="w-full" formControlName="password" id="password" name="password" pInputText
                   placeholder="Enter your password" type="password">
            <label htmlFor="password">Password</label>
          </div>
          <p-message *ngIf="hasErrorAndTouched(loginForm.get('password'), 'required')"
                     severity="error" text="This field is required."></p-message>
        </div>

        <div class="h-[3.7rem]">
          <button [disabled]="disableSubmitForm"
                  class="w-full"
                  label="Login"
                  pButton pRipple type="submit"></button>
        </div>
      </form>
    </div>

    <div
      class="absolute flex flex-row bottom-0 right-0 p-2 w-full p-auto items-center justify-end bg-secondary-500 space-x-3">
      <span class="text-white font-bold">powered by</span>
      <img alt="Logo" class="h-5" src="assets/images/logo-white.svg">
    </div>
  </div>
</div>
