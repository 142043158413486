import { Component, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { passwordMatchValidator } from '@shared/validators';
import { StaffsService, StaffsServiceState } from '@modules/staffs/services';
import { Staff } from '@core/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SharedModule } from '@shared/shared.module';
import { DialogService } from 'primeng/dynamicdialog';
import { PasswordValidators } from 'ngx-validators';
import { hasErrorAndTouched } from '@shared/form-controls.util';

@UntilDestroy()
@Component({
  selector: 'app-staff-password-form',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './staff-password-form.component.html',
  styleUrls: ['./staff-password-form.component.scss'],
})
export class StaffsFormPasswordComponent {
  readonly staffServiceState: Signal<StaffsServiceState> = this.staffService.state.asReadonly();
  readonly selectedStaff: Staff | undefined = this.staffServiceState().selectedStaff;
  readonly hasErrorAndTouched = hasErrorAndTouched;

  userForm = this.fb.group({
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        PasswordValidators.alphabeticalCharacterRule(1),
        PasswordValidators.digitCharacterRule(1),
        PasswordValidators.lowercaseCharacterRule(1),
        PasswordValidators.uppercaseCharacterRule(1),
        PasswordValidators.specialCharacterRule(1),
      ],
    ],
    confirmedPassword: new FormControl('', {
      validators: [Validators.required, passwordMatchValidator()],
    }),
  });

  constructor(
    private fb: FormBuilder,
    private staffService: StaffsService,
    public dialogService: DialogService,
  ) {}

  onPasswordChange() {
    this.userForm.controls.confirmedPassword.updateValueAndValidity();
  }

  onSubmit() {
    const passwords: any = { ...this.userForm.value };
    this.staffService
      .changPassword(this.selectedStaff?.id as string, passwords)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogService.dialogComponentRefMap.forEach((dialog) => {
          dialog.destroy();
        });
      });
  }
}
