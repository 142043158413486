import { Component, OnInit, Signal } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RolesService, RolesServiceState, UsersService, UsersServiceState } from '@modules/users/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { User } from '@core/models';
import { hasErrorAndTouched } from '@shared/form-controls.util';
import { EmailValidators, PasswordValidators } from 'ngx-validators';
import { DialogService } from 'primeng/dynamicdialog';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { AvailableValidator } from '@shared/validators/available.validator';

@UntilDestroy()
@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class UserFormComponent implements OnInit {
  // The component subscribe and fetch data from service state
  readonly usersServiceState: Signal<UsersServiceState> = this.usersService.state.asReadonly();
  readonly rolesServiceState: Signal<RolesServiceState> = this.rolesService.state.asReadonly();
  readonly isEditMode = this.usersServiceState().formEditMode;
  readonly selectedUser: User | undefined = this.usersServiceState().selectedUser;
  readonly hasErrorAndTouched = hasErrorAndTouched;
  userForm: UntypedFormGroup = this.fb.group(
    {
      name: ['', Validators.required],
      email: ['', [Validators.required, EmailValidators.normal]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          PasswordValidators.alphabeticalCharacterRule(1),
          PasswordValidators.digitCharacterRule(1),
          PasswordValidators.lowercaseCharacterRule(1),
          PasswordValidators.uppercaseCharacterRule(1),
          PasswordValidators.specialCharacterRule(1),
        ],
      ],
      confirmPassword: ['', Validators.required],
      roles: [[]],
      phone: [''],
      department: [''],
    },
    {
      validators: [PasswordValidators.mismatchedPasswords('password', 'confirmPassword')],
    },
  );

  constructor(
    private fb: FormBuilder,
    private usersService: UsersService,
    public dialogService: DialogService,
    private rolesService: RolesService,
    private uniqueFieldValidator: AvailableValidator,
  ) {}

  get disableSubmitForm(): boolean {
    return this.userForm.invalid;
  }

  ngOnInit() {
    if (this.isEditMode) {
      this.userForm.get('password')?.clearValidators();
      this.userForm.get('confirmPassword')?.clearValidators();
      this.userForm.get('email')?.disable();
      this.userForm.patchValue(this.selectedUser as User);
    } else {
      this.userForm.get('email')?.addAsyncValidators(this.uniqueFieldValidator.validate('users', 'email'));
    }
  }

  onSubmit() {
    const payload: User = { ...this.userForm.value };

    if (this.isEditMode) {
      this.usersService.editUser(this.selectedUser?.id as string, payload).subscribe();
    } else {
      this.usersService.newUser(payload).subscribe();
    }
    this.usersService.set('selectedUser', undefined);
    this.usersService.set('formEditMode', false);
    this.dialogService.dialogComponentRefMap.forEach((dialog) => {
      dialog.destroy();
    });
  }
}
