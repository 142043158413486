import { Component, OnInit, Signal } from '@angular/core';
import { RolesService, UsersService, UsersServiceState } from './services';
import { User } from '@core/models';
import { Title } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserDatatableComponent, UserFiltersComponent, UserFormComponent } from '@modules/users/components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { UserPasswordFormComponent } from '@modules/users/components/user-password-form/user-password-form.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, UserDatatableComponent, UserFiltersComponent],
})
@UntilDestroy()
export class UsersComponent implements OnInit {
  readonly title = 'Users Management';
  // The component subscribe and fetch data from service state
  readonly usersServiceState: Signal<UsersServiceState> = this.usersService.state.asReadonly();
  ref: DynamicDialogRef | undefined;

  constructor(
    private titleService: Title,
    private usersService: UsersService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private rolesService: RolesService,
  ) {}

  ngOnInit() {
    this.rolesService.fetchRoles().pipe(untilDestroyed(this)).subscribe();
    this.titleService.setTitle(this.title);
  }

  viewUser(user: User) {
    this.usersService.set('selectedUser', user);
  }

  newUser() {
    this.usersService.set('selectedUser', undefined);
    this.usersService.set('formEditMode', false);
    this.openForm();
  }

  editUser(user: User) {
    this.usersService.set('selectedUser', user);
    this.usersService.set('formEditMode', true);
    this.openForm();
  }

  deleteUser(user: User) {
    this.usersService.set('selectedUser', user);
    this.confirmationService.confirm({
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to delete ?',
      accept: () => {
        this.usersService.deleteUser(user.id).pipe(untilDestroyed(this)).subscribe();
      },
    });
  }

  changePassword(user: User) {
    this.usersService.set('selectedUser', user);
    this.ref = this.dialogService.open(UserPasswordFormComponent, {
      header: 'Change user password',
      styleClass: 'p-fluid',
      width: '50vw',
      modal: true,
      draggable: true,
    });
  }

  private openForm() {
    this.ref = this.dialogService.open(UserFormComponent, {
      header: this.usersServiceState().formEditMode ? 'Edit User' : 'New User',
      styleClass: 'p-fluid',
      width: '50vw',
      modal: true,
      draggable: true,
    });
  }
}
