import { animate, query, style, transition, trigger } from '@angular/animations';

export const fader = trigger('routeAnimations', [
  transition('* <=> *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          width: 'calc(100% - 35px)',
          opacity: 0,
        }),
      ],
      { optional: true },
    ),
    query(':enter', [animate('1000ms ease', style({ opacity: 1 }))], { optional: true }),
    query(':leave', [animate('1000ms ease', style({ opacity: 0 }))], { optional: true }),
  ]),
]);
