import { Directive } from '@angular/core';
import { AbstractControl, Validator } from '@angular/forms';

/**
 * Custom directive to validate IP addresses.
 */
@Directive({
  selector: '[appIpAddressValidator]',
})
export class IpAddressValidatorDirective implements Validator {
  constructor() {}

  /**
   * Validates the IP address control.
   * @param control - The form control to validate.
   * @returns An object with the `invalidIp` property set to `true` if the IP address is invalid, otherwise `null`.
   */
  validate(control: AbstractControl) {
    const ipAddress = control.value;
    const segments = ipAddress.split('.');

    // Check if there are 4 segments
    if (segments.length !== 4) {
      return { invalidIp: true };
    }

    // Check each segment
    for (const segment of segments) {
      const segmentValue = parseInt(segment, 10);

      // Check if the segment is not a valid number or is greater than 255
      if (isNaN(segmentValue) || segmentValue > 255) {
        return { invalidIp: true };
      }
    }

    return null;
  }
}
