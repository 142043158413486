<form (ngSubmit)="onSubmit()" [formGroup]="itemForm" autocomplete="off" class="w-full mt-5">
  <div class="flex flex-col">
    <!-- Asset Data-->
    <div class="w-full mb-8">
        <span class="p-float-label">
          <input class="w-full" formControlName="tagId" id="tagId" pInputText/>
          <label htmlFor="tagId">Tag ID*</label>
        </span>
      <p-message *ngIf="hasErrorAndTouched(itemForm.get('tagId'), 'required')"
                 severity="error" text="*Tag Id is required."></p-message>
    </div>

    <div class="w-full mb-8">
        <span class="p-float-label">
          <p-dropdown (onChange)="onVendorChange($event)" [filter]="true" [options]="vendorServiceState().vendors"
                      [required]="true"
                      [scrollHeight]="'200px'"
                      formControlName="vendorId"
                      id="vendorId" optionLabel="name"
                      optionValue="id"></p-dropdown>
          <label htmlFor="vendorId">Vendor*</label>
        </span>
      <p-message *ngIf="hasErrorAndTouched(itemForm.get('vendorId'), 'required')"
                 severity="error" text="*Vendor is required."></p-message>
    </div>

    <div class="w-full mb-8">
        <span class="p-float-label">
          <p-dropdown [filter]="true" [options]="locationsServiceState().locations"
                      [scrollHeight]="'200px'"
                      formControlName="locationId"
                      id="locationId"
                      optionLabel="name"
                      optionValue="id"></p-dropdown>
          <label htmlFor="locationId">Location*</label>
        </span>
      <p-message *ngIf="hasErrorAndTouched(itemForm.get('locationId'), 'required')"
                 severity="error" text="*Location is required."></p-message>
    </div>

    <div class="w-full mb-8">
        <span class="p-float-label">
          <p-dropdown [filter]="true" [options]="assetTypesServiceState().types" [required]="true"
                      [scrollHeight]="'200px'"
                      formControlName="assetTypeId"
                      id="assetType"
                      optionLabel="name"
                      optionValue="id"></p-dropdown>
          <label htmlFor="assetType">Asset Type*</label>
        </span>
      <p-message *ngIf="hasErrorAndTouched(itemForm.get('assetTypeId'), 'required')"
                 severity="error" text="*Asset type is required."></p-message>
    </div>

    <!-- Content for the Info -->
    <div class="w-full mb-6">
        <span class="p-float-label">
          <textarea [autoResize]="true" cols="30" formControlName="info1" id="info1" pInputTextarea rows="2"></textarea>
          <label for="info1">Info 1</label>
        </span>
    </div>
    <div class="w-full mb-6">
        <span class="p-float-label">
          <textarea [autoResize]="true" cols="30" formControlName="info2" id="info2" pInputTextarea
                    rows="2"></textarea>
          <label for="info2">Info 2</label>
        </span>
    </div>
    <div class="w-full mb-6">
        <span class="p-float-label">
          <textarea [autoResize]="true" cols="30" formControlName="info3" id="info3" pInputTextarea rows="2"></textarea>
          <label for="info3">Info 3</label>
        </span>
    </div>

  </div>

  <div class="flex flex-row space-x-4">
    <button [disabled]="disableSubmitForm"
            class="p-button p-ripple p-component mt-3" label="Save" pButton pRipple type="submit"></button>
  </div>
</form>
